import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Close from "@material-ui/icons/Close";
import Icon from "@material-ui/core/Icon";
// core components
import HeaderLinks from "components/Header/HeaderLinks.jsx";

import sidebarStyle from "assets/jss/material-dashboard-react/components/sidebarStyle.jsx";

const Sidebar = ({ ...props }) => {
    // verifies if routeName is the one active (in browser input)
    function activeRoute(routeName) {
        return props.location.pathname.indexOf(routeName) > -1 ? true : false;
    }
    const { classes, color, logo, image, routes } = props;
    var links = (
        <List className={classes.list}>
            {routes.map((prop, key) => {
                if (prop.path === "/changepassword" || prop.path === "/dashboard" || prop.path === "/userprofile") return null;
                if (prop.redirect) return null;
                var listItemClasses = classNames({
                    [" " + classes[color]]: activeRoute(prop.path)
                });
                const whiteFontClasses = classNames({
                    [" " + classes.whiteFont]: activeRoute(prop.path)
                });
                return (
                    <NavLink
                        to={prop.path}
                        className={classes.item}
                        activeClassName="active"
                        key={key}
                    >
                        <ListItem button className={classes.itemLink + listItemClasses}>
                            <ListItemIcon className={classes.itemIcon + whiteFontClasses}>
                                {typeof prop.icon === "string" ? (
                                    <Icon>{prop.icon}</Icon>
                                ) : (
                                        <prop.icon />
                                    )}
                            </ListItemIcon>
                            <ListItemText
                                primary={prop.sidebarName}
                                className={classes.itemText + whiteFontClasses}
                                disableTypography={true}
                            />
                        </ListItem>
                    </NavLink>
                );
            })}
        </List>
    );
    var brand = (
        <div className={classes.logo}>
            <Grid container direction="row" justify="space-between">
                <Grid container item xs={2} sm={2} md={2}></Grid>
                <Grid container item xs={8} sm={8} md={8}>
                    <a href="https://ift.world/" className={classes.logoLink}>
                        <div className={classes.logoImage}>
                            <img src={logo} alt="logo" className={classes.img} />
                        </div>
                    </a>
                </Grid>
                <Grid container item xs={2} sm={2} md={2}>
                    <Hidden smDown implementation="css">
                        <IconButton color="primary" onClick={props.toggleDrawer}>
                            <Close />
                        </IconButton>
                    </Hidden>
                </Grid>
            </Grid>
        </div>
    );
    return (
        <div>
            <Hidden mdUp implementation="css">
                <Drawer
                    variant="temporary"
                    anchor="right"
                    open={props.open}
                    classes={{
                        paper: classes.drawerPaper
                    }}
                    onClose={props.handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true // Better open performance on mobile.
                    }}
                >
                    {brand}
                    <div className={classes.sidebarWrapper}>
                        <HeaderLinks history={props.history} logout={props.logout}/>
                        {links}
                    </div>
                </Drawer>
            </Hidden>
            <Hidden smDown implementation="css">
                <Drawer
                    anchor="left"
                    variant="persistent"
                    open={props.openDrawer}
                    classes={{
                        paper: classes.drawerPaper
                    }}
                    SlideProps={{
                        unmountOnExit: true
                    }}
                    onClose={props.toggleDrawer}
                >
                    {brand}
                    <div className={classes.sidebarWrapper}>{links}</div>
                    {image !== undefined ? (
                        <div
                            className={classes.background}
                            style={{ backgroundImage: "url(" + image + ")" }}
                        />
                    ) : null}
                </Drawer>
            </Hidden>
        </div>
    );
};

Sidebar.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(sidebarStyle)(Sidebar);
