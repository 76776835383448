import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { Grid } from "@material-ui/core";

// core components
import { connect } from "react-redux";
import _ from "lodash";
import Spinner from "react-spinner-material";

// custom components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "../../components/CustomButtons/Button";
import { mapStateToProps, mapDispatchToProps } from "./functions";
import CkEditor from "../../components/CkEditor/CkEditor";

const styles = {
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "400",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class TermsAndConditions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      content: "",
      editorState: "",
      allowSetStateFromProps: true
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.allowSetStateFromProps && _.has(nextProps.termsAndConditions, "content")) {
        return {
          editorState: nextProps.termsAndConditions.content,
          allowSetStateFromProps: false
        };
    }

    return null;
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.props.getTermsAndConditions(() => {
        this.setState({ loading: false });
    });
  }

  setTermsAndConditions = () => {
    this.setState({ loading: true });
    this.props.setTermsAndConditions({ content: this.state.editorState }, (status) => {
      this.setState({loading: false})
    });
  }

  render() {
    const { classes } = this.props;
    const { editorState } = this.state;

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Terms & Conditions</h4>
            </CardHeader>
            <CardBody>

            {this.state.loading 
              ?
                <Grid container item justify="center">
                    <Spinner size={50} spinnerColor="#0093cb" spinnerWidth={5} visible={true} />
                </Grid>
              :
                <>
                  <CkEditor
                    editorState={editorState}
                    onChange={(data) => {
                      this.setState({editorState: data});
                    }}
                  />

                  <Grid container justify="flex-end">
                    <Button
                      type="submit"
                      color="primary"
                      size="lg"
                      onClick={this.setTermsAndConditions}
                      disabled={!editorState}
                    >
                      Submit
                    </Button>
                  </Grid>
                </>
            }
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TermsAndConditions));
