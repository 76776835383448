import axios from 'axios';
import _ from "lodash";
import Constants from '../config/constants.js';
import {logoutUser} from "./../redux/actions/user";
import {hist} from "./../index";

let cancelCallTokens = {};
export function apiWithCallbacks(params = {}, onSuccess, onFailure, token) {
    return (dispatch) => {
        if(params.header && _.isNil(params.header.access_token)) return
        let mainUrl = Constants.BASE_URL + params.path
        callApi(params, mainUrl).then(function (res) {
            if (res && res.status === 200) {
                dispatch(onSuccess(res, params, token))
            }
            else if (res && res.status === 401 && params.path !== "account/login"){
                dispatch(logoutUser());
                hist.push("/login");
            }
            else {
                if (res && res.data) {
                    dispatch(onFailure(res.data, params))
                }
                else {
                    let response = "Unable to connect to server"
                    dispatch(onFailure(response, params))
                }
            }
        }).catch(function (error) {
            dispatch(onFailure(error, params))
        })
    }
}

function callApi(params, url) {
    console.log("PARAMS", params, url)
    var CancelToken = axios.CancelToken;
    _.forEach(cancelCallTokens, function(value, key) {
        if(params.path === key && value){
            value.cancel("Cancelled by new call");
        }
    });
    cancelCallTokens[params.path] = CancelToken.source();
    return axios({
        method: params.method ? params.method : 'POST',
        url: url,
        data: params.data,
        params: (params.params) ? params.params : {},
        headers: createHeaderObject(params),
        responseType: params.responseType && params.responseType,
        cancelToken: cancelCallTokens[params.path].token
    }).then(function (response) {
        // console.log("in call APi then", response);
        _.forEach(cancelCallTokens, function(value, key) {
            if(params.path === key){
                cancelCallTokens[key] = false;
            }
        });
        return response
    }).catch(function (error) {
        console.log("req failed", error.response);
        _.forEach(cancelCallTokens, function(value, key) {
            if(params.path === key){
                cancelCallTokens[key] = false;
            }
        });
        return error.response
    })
}

function createHeaderObject(params) {
    let header = {}
    if (params.header && params.header.access_token) {
        header = { 'Authorization': 'Bearer ' + params.header.access_token }
    } else if (params.header && params.header.refresh_token) {
        header = { 'Authorization': 'Bearer ' + params.header.refresh_token }
    }
    return header
}