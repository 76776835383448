import _ from "lodash";
import {apiWithCallbacks} from "./../../helpers/apiManager";
import {feedbackQuestions, feedbacksList, setRowsPerPage, totalFeedbackRecords} from "./../../redux/actions/appData";

export const mapStateToProps = (state) => {
    return {
        feedbackQuestions: state.appData.feedbackQuestions,
        feedbacks: state.appData.feedbacks,
        rowsPerPage: state.appData.rowsPerPage,
        totalFeedbacks: state.appData.totalFeedbackRecords
    }
}

export const mapDispatchToProps = (dispatch) => {
    return {
        getFeedbackQuestions: (limit, skip, callback) => dispatch(getFeedbackQuestions(limit, skip, callback)),
        setRowsPerPage: rows => dispatch(setRowsPerPage(rows))
    }
}

/*---------------- Get Feedback Questions -------------*/
const getFeedbackQuestions = (limit, skip, callback) => {
    return (dispatch, getState) => {
        let params = {
            method: "GET",
            path: "feedbackquestions",
            header: {
                access_token: getState().user.user.authToken
            },
            limit, skip, callback
        }
        dispatch(apiWithCallbacks(params, onSuccessGetQuestions, onFailureGetQuestions));
    }
}
const onSuccessGetQuestions = (response, params) => {
    return (dispatch) => {
        console.log("onSuccessGetQuestions", response, params);
        dispatch(feedbackQuestions(response.data));
        dispatch(getFeedbacks(params.limit, params.skip, params.callback));
    }
}
const onFailureGetQuestions = (response, params) => {
    return (dispatch) => {
        console.log("onFailureGetQuestions", response, params);
        params.callback();
    }
}

/*------------------ Get Feedbacks -------------------*/
const getFeedbacks = (limit, skip, callback) => {
    return (dispatch, getState) => {
        let params = {
            method: "GET",
            path: "feedbacks",
            header: {
                access_token: getState().user.user.authToken
            },
            params: {
                limit, skip
            },
            callback
        }
        dispatch(apiWithCallbacks(params, onSuccessGetFeedbacks, onFailureGetFeedbacks));
    }
}
const onSuccessGetFeedbacks = (response, params) => {
    return (dispatch) => {
        console.log("onSuccessGetFeedbacks", response, params);
        const feedbacks = _.map(response.data.feedbacks, feedback => {
            let email = "";
            try{email = feedback.user.email}catch(e){}
            const id = feedback.id;
            return _.assign(feedback.response, {email ,id});
        });
        dispatch(feedbacksList(feedbacks));
        dispatch(totalFeedbackRecords(response.data.totalFeedbacks));
        params.callback();
    }
}
const onFailureGetFeedbacks = (response, params) => {
    return (dispatch) => {
        console.log("onFailureGetFeedbacks", response, params);
        params.callback();
    }
}