import React from "react";
import {Formik, Form} from "formik";
import {connect} from "react-redux";
import Spinner from "react-spinner-material";
import withStyles from "@material-ui/core/styles/withStyles";
import _ from "lodash";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import {
    Grid, TextField, Card, CardContent, CardActions
} from "@material-ui/core";
import Button from "./../../components/CustomButtons/Button.jsx";
import {validateEmail, validatePassword} from "./../../helpers/helpers";
import {mapStateToProps, mapDispatchToProps} from "./function";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#0093cb'
        },
        default: {
            main: "#cccccc"
        }
    }
});

const styles = {
    mainContainer: {
        minHeight: '100vh'
    },
    outerContainer: {
        minWidth: "100vw"
    },
    card: {
        padding: 20
    },
    media: {
        height: 140,
    },
    imgLogo: {
        width: "40%"
    },
    title: {
        fontWeight: "500",
        marginBottom: 0
    }
};

class Login extends React.Component{
    componentDidMount(){
        if (!_.isNil(this.props.user.authToken) && !_.isEmpty(this.props.user.authToken)){
            this.props.history.push("/users");
        }
    }
    loginCallback = (status, setSubmitting) => {
        if(status){
            setSubmitting(false);
            this.props.history.push("/users");
        }
        else{
            setSubmitting(false);
        }
    }
    submitForm = (values, { setSubmitting }) => {
        let data = {
            email: values.email,
            password: values.password,
            via: "admin"
        }
        this.props.login(data, (status) => this.loginCallback(status, setSubmitting));
    }
    render(){
        const { classes } = this.props;
        return(
            <MuiThemeProvider theme={theme}>
                <Grid container spacing={0} direction="column" justify="center" className={classes.mainContainer}>
                    <Grid container item direction="row" justify="center" className={classes.outerContainer}>
                        <Grid container item xs={10} sm={8} md={4} justify="center">
                            <Formik
                                initialValues={{ email: "", password: "", }}
                                validate={values => {
                                    let errors = {};
                                    let email = validateEmail(values.email);
                                    let password = validatePassword(values.password);
                                    if(!_.isNil(email)) errors.email = email;
                                    if(!_.isNil(password)) errors.password = password;
                                    return errors;
                                }}
                                onSubmit={this.submitForm}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting
                                }) => (
                                    <Form>
                                        <Card className={classes.card}>
                                            <CardContent>
                                                <Grid container direction="column">
                                                    <Grid container item direction="row" justify="center" alignItems="center">
                                                        <img src={require("./../../assets/img/ift_logo2.png")} className={classes.imgLogo} alt="ift-logo"/>
                                                    </Grid>
                                                    <Grid>
                                                        <h4 className={classes.title}>Login</h4>
                                                    </Grid>
                                                    <Grid>
                                                        <TextField
                                                            label="Email"
                                                            placeholder="Enter Email Address"
                                                            name="email"
                                                            margin="dense"
                                                            fullWidth
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.email}
                                                            helperText={errors.email && touched.email && errors.email}
                                                            error={errors.email && touched.email}
                                                        />
                                                        <TextField
                                                            label="Password"
                                                            placeholder="Enter Password"
                                                            name="password"
                                                            type="password"
                                                            margin="dense"
                                                            fullWidth
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.password}
                                                            helperText={errors.password && touched.password && errors.password}
                                                            error={errors.password && touched.password}
                                                        />
                                                    </Grid>  
                                                </Grid>
                                            </CardContent>
                                            <CardActions>
                                                <Grid container item justify="center">
                                                    {isSubmitting
                                                        ? <Spinner size={50} spinnerColor="#0093cb" spinnerWidth={5} visible={true} />
                                                        : <Button type="submit" color="primary" onClick={handleSubmit} disabled={isSubmitting}>Login</Button>
                                                    }
                                                </Grid>
                                            </CardActions>
                                        </Card>
                                    </Form>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </Grid>
            </MuiThemeProvider>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Login));