import React, { Component } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {
    FormControl, InputLabel, Input, InputAdornment, IconButton
} from "@material-ui/core";
import {connect} from "react-redux";
import _ from "lodash";
import Spinner from "react-spinner-material";
import Search from "@material-ui/icons/Search";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "components/Table/Table.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import {mapStateToProps, mapDispatchToProps} from "./functions";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "400",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    textField: {
        marginTop: 0
    },
    emptyListMsg: {
        paddingTop: 20,
        paddingBottom: 20,
        margin: 0,
        color: "#0093cb"
    }
};

class Feedback extends Component {
    state = {
        feedbacks: [],
        filterBy: "email",
        searchText: "",
        tableHead: [],
        page: 0,
        loading: true
    }
    componentDidMount(){
        this.props.getFeedbackQuestions(this.props.rowsPerPage, this.state.page * this.props.rowsPerPage, (res) => {
            this.setState({loading: false})
        });
    }
    componentDidUpdate(prevProps, prevState){
        if (prevState.page !== this.state.page || prevProps.rowsPerPage !== this.props.rowsPerPage) {
            this.setState({loading: true});
            this.props.getFeedbackQuestions(this.props.rowsPerPage, this.state.page * this.props.rowsPerPage, (res) => {
                this.setState({ loading: false })
            });
        }
    }
    static getDerivedStateFromProps(props, state) {
        let filtered = _.filter(props.feedbacks, (feedback) => feedback[state.filterBy].toLowerCase().indexOf(state.searchText.toLowerCase()) !== -1);
        let tableHead = [...tableHeadings, ...props.feedbackQuestions];
        tableHead = _.map(tableHead, head => (head.question ? { id: head.id, label: head.question, sortable: false } : { ...head }));
        return {
            feedbacks: filtered,
            tableHead
        }
    }
    handleChangePage = (event, page) => {
        this.setState({ page });
    }
    handleChangeRowsPerPage = event => {
        this.setState({ page: 0 });
        this.props.setRowsPerPage(event.target.value);
    }
    searchByChange = event => {
        this.setState({ filterBy: event.target.value });
    }
    searchTextChange = event => {
        this.setState({ searchText: event.target.value })
    }
    render(){
        const { classes, rowsPerPage, totalFeedbacks } = this.props;
        const { searchText, tableHead, feedbacks, page, loading } = this.state;
        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className={classes.cardTitleWhite}>Feedback</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer justify="flex-end">
                                <GridItem xs={12} sm={6} md={4}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="search">Search</InputLabel>
                                        <Input
                                            id="search"
                                            placeholder="Search by Email"
                                            value={searchText}
                                            onChange={this.searchTextChange}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton color="primary">
                                                        <Search />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                            {loading
                                ?
                                <GridContainer justify="center">
                                    <Spinner size={50} spinnerColor="#0093cb" spinnerWidth={5} visible={true} />
                                </GridContainer>
                                :
                                !_.isEmpty(feedbacks)
                                    ?
                                    <Table
                                        tableHeaderColor="primary"
                                        tableHead={tableHead}
                                        tableData={feedbacks}
                                        page={page}
                                        rowsPerPage={rowsPerPage}
                                        handleChangePage={this.handleChangePage}
                                        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                                        totalNumber={totalFeedbacks}
                                    />
                                    :
                                    <GridContainer justify="center">
                                        <h4 className={classes.emptyListMsg}>No Feedback Found</h4>
                                    </GridContainer>
                            }
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Feedback));

const tableHeadings = [
    { id: "id", label: "Sr#", sortable: false },
    { id: "email", label: "User Email", sortable: true }
];