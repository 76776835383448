import {apiWithCallbacks} from "./../../helpers/apiManager";
import {alertPopupAction} from "./../../redux/actions/generalConfig";
import {loginUser} from "./../../redux/actions/user";

export const mapStateToProps = (state) => {
    return {
        user: state.user.user
    }
}
export const mapDispatchToProps = (dispatch) => {
    return {
        login: (data, callback) => dispatch(loginAdmin(data, callback))
    }
}

/*----------------- Login ApiCall ----------------*/
const loginAdmin = (data, callback) => {
    return (dispatch, getState) => {
        dispatch(alertPopupAction(false, "", getState().generalConfig.alertPopup.color));
        let params = {
            method: "POST",
            path: "account/login",
            data,
            callback
        }
        dispatch(apiWithCallbacks(params, onSuccessLogin, onFailureLogin));
    }
}
const onSuccessLogin = (response, params) => {
    return (dispatch) => {
        console.log("onSuccessLogin", response);
        dispatch(loginUser(response.data));
        params.callback(true);
    }
}
const onFailureLogin = (response, params) => {
    return (dispatch) => {
        console.log("onFailureLogin", response);
        params.callback(false);
        if(response) dispatch(alertPopupAction(true, response, "danger"));
        else dispatch(alertPopupAction(true, "Login Failed", "danger"));
    }
}