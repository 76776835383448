import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {connect} from "react-redux";
import _ from "lodash";
import {
    TextField, Grid
} from "@material-ui/core";
import { Formik, Form } from "formik";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "./../../components/CustomButtons/Button";
import {mapStateToProps, mapDispatchToProps} from "./functions";
import {validateEmail} from "./../../helpers/helpers"

const styles = {
    container: {
        padding: 10
    },
    heading: {
        fontWeight: "500",
        marginBottom: 0
    }
};

class ChangePassword extends React.Component {
    callback = (status, setSubmitting, resetForm) => {
        if(status) resetForm({email: ""});
        setSubmitting(false);
    }
    submitForm = (values, { setSubmitting, resetForm }) => {
        let data = {
            email: values.email
        }
        this.props.changePassword(data, (status) => this.callback(status, setSubmitting, resetForm));
    }
    render() {
        const { classes } = this.props;
        return (
            <GridContainer justify="center">
                <GridItem xs={12} sm={8} md={6}>
                    <Card>
                        <CardBody>
                            <Formik
                                initialValues={{ email: ""}}
                                validate={values => {
                                    let errors = {};
                                    let email = validateEmail(values.email);
                                    if (!_.isNil(email)) errors.email = email;
                                    return errors;
                                }}
                                onSubmit={this.submitForm}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting
                                }) => (
                                    <Form>
                                        <Grid container direction="column">
                                            <Grid container item className={classes.container}>
                                                <h4 className={classes.heading}>Reset Password</h4>
                                            </Grid>
                                            <Grid container item className={classes.container}>
                                                <TextField
                                                    label="Email"
                                                    placeholder="Enter Email"
                                                    margin="dense"
                                                    type="text"
                                                    fullWidth
                                                    name="email"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={errors.email && touched.email && errors.email}
                                                    error={errors.email && touched.email}
                                                />
                                            </Grid>
                                            <Grid container item justify="center" className={classes.container}>
                                                <Button type="submit" color="primary" onClick={handleSubmit} disabled={isSubmitting}>Reset</Button>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                            </Formik>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ChangePassword));