import React from "react";
import {connect} from "react-redux";
import SnackBar from "@material-ui/core/Snackbar";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Error from "@material-ui/icons/Error";
import SnackbarContent from "./../Snackbar/SnackbarContent";
import {alertPopupAction} from "./../../redux/actions/generalConfig";

class AlertPopup extends React.PureComponent{

    closePopup = () => this.props.hideAlertPopup(false, "", this.props.alertPopup.color)
    render(){
        const {open, message, color} = this.props.alertPopup;
        const icon = color === "danger" ? Error : CheckCircle; 
        return(
            <SnackBar
                open={open} message={message} 
                anchorOrigin={{horizontal: "right", vertical: "top"}}
                autoHideDuration={3000}
                onClose={this.closePopup}
            >
                <SnackbarContent message={message} color={color} close onClose={this.closePopup} icon={icon}/>
            </SnackBar>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        alertPopup: state.generalConfig.alertPopup
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        hideAlertPopup: (status, message, color) => dispatch(alertPopupAction(status, message, color))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertPopup);