import _ from "lodash";
import { apiWithCallbacks } from "./../../helpers/apiManager";
import { alertPopupAction } from "./../../redux/actions/generalConfig";
import { upgradeMessage } from "./../../redux/actions/appData";

export const mapStateToProps = state => {
  return {
	upgradeMessage: state.appData.upgradeMessage
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    getUpgradeMessage: cb => dispatch(getUpgradeMessage(cb)),
    setUpgradeMessage: (data, cb) => dispatch(setUpgradeMessage(data, cb))
  };
};

/*--------------- Get Package Upgrade Message ApiCall ------------*/
const getUpgradeMessage = (callback) => {
  return (dispatch, getState) => {
    dispatch(
      alertPopupAction(false, "", getState().generalConfig.alertPopup.color)
    );
    let params = {
        method: 'GET',
        path: 'packageurls',
        header: {
            access_token: getState().user.user.authToken
        },
        callback
    };
    dispatch(apiWithCallbacks(params, onSuccessGetUpgradeMessage, onFailureGetUpgradeMessage));
  };
};
function onSuccessGetUpgradeMessage(response, params) {
	return (dispatch) => {
		console.log("onSuccessGetUpgradeMessage: ", response, params);
		if (response.data && _.isArray(response.data) && response.data.length > 0) {
			dispatch(upgradeMessage(response.data));
		}
		if(_.has(params, "callback") && _.isFunction(params.callback)) {
			params.callback(true);
		}
	}
}
function onFailureGetUpgradeMessage(response, params) {
	return (dispatch) => {
		console.log("onFailureGetUpgradeMessage: ", response, params);
		dispatch(alertPopupAction(true, "Error in setting Upgrade message", "danger"));
        if(_.has(params, "callback") && _.isFunction(params.callback)) params.callback(false);
	}
}

/*----------------- Set Package Upgrade Message -----------------*/
const setUpgradeMessage = (data, callback) => {
    return (dispatch, getState) => {
        dispatch(
            alertPopupAction(false, "", getState().generalConfig.alertPopup.color)
		);
		let method = "POST";
		let path = "packageurls";
		if (data.id && !_.isEmpty(data.id)) {
			method = "PUT";
			path = `${path}/${data.id}`;
		}
        let params = {
            method: method,
            path: path,
            data,
            header: {
                access_token: getState().user.user.authToken
            },
            callback
        }
        dispatch(apiWithCallbacks(params, onSuccessSetUpgradeMessage, onFailureSetUpgradeMessage));
    }
}
function onSuccessSetUpgradeMessage(response, params) {
	return (dispatch, getState) => {
		console.log("onSuccessSetUpgradeMessage: ", response, params);
		let messages = getState().appData.upgradeMessage;
		if(response.data && _.isObject(response.data)) {
			if (params.data.id && !_.isEmpty(params.data.id)) {
				const msgIndex = _.findIndex(messages, (msg) => params.data.id === msg.id);
				if (msgIndex == -1) {
					messages = _.concat(messages, response.data);
				} else {
					messages = _.assign([...messages], { [msgIndex]: response.data });
				}
			} else {
				messages = _.concat(messages, response.data);
			}
			dispatch(upgradeMessage(messages));
		}
		if(_.has(params, "callback") && _.isFunction(params.callback)) {
			params.callback(true);
		}
		dispatch(alertPopupAction(true, "Upgrade Package message updated", "success"));
	}
}
function onFailureSetUpgradeMessage(response, params) {
	return (dispatch) => {
		console.log("onFailureSetUpgradeMessage: ", response, params);
		dispatch(alertPopupAction(true, "Error in setting Ugrade message", "danger"));
        if(_.has(params, "callback") && _.isFunction(params.callback)) params.callback(false);
	}
}