const initialState = {
    loading: false,
    alertPopup: {
        open: false,
        message: "",
        color: "success"
    }
}

export default function(state = initialState, action){
    switch(action.type){
        case "LOADING":
            return {...state, loading: action.status}
        case "ALERT_POPUP":
            return {...state, alertPopup: action.data}
        default:
            return state
    }
}