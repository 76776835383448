import _ from "lodash";
import {apiWithCallbacks} from "./../../helpers/apiManager";
import {getAllUsers} from "./../Users/functions";
import {alertPopupAction} from "./../../redux/actions/generalConfig";

export const mapStateToProps = (state) => {
    return {
        usersList: state.appData.users,
        totalUsers: state.appData.totalUserRecords
    }
}
export const mapDispatchToProps = (dispatch) => {
    return {
        getAllUsers: (limit, skip, searchText, searchBy, callback) => dispatch(getAllUsers(limit, skip, searchText, searchBy, callback)),
        sendNotification: (data, callback) => dispatch(sendNotification(data, callback)),
        saveLevels: (callback) => dispatch(saveLevels(callback)),
        getLevels: (callback) => dispatch(getLevels(callback)),
        countUsers: (selectLevel, selectSessions, selectAllLevel, callback) => dispatch(countUsers(selectLevel, selectSessions, selectAllLevel, callback)),
        allEmails: (callback) => dispatch(allEmails(callback))
    }
}

/*--------------- Send Notification ApiCall ------------*/
const sendNotification = (data, callback) => {
    return (dispatch, getState) => {
        dispatch(alertPopupAction(false, "", getState().generalConfig.alertPopup.color));
        let params = {
            method: "POST",
            path: "notify",
            data,
            header: {
                access_token: getState().user.user.authToken
            },
            callback
        }
        dispatch(apiWithCallbacks(params, onSuccessSendNotif, onFailureSendNotif));
    }
}
const onSuccessSendNotif = (response, params) => {
    return (dispatch) => {
        console.log("onSuccessSendNotif", response, params);
        params.callback(true)
        if (_.isString(response.data) && !_.isEmpty(response.data)) dispatch(alertPopupAction(true, response.data, "success"));
        else dispatch(alertPopupAction(true, "Notification Sent Successfully", "success"));
    }
}
const onFailureSendNotif = (response, params) => {
    return (dispatch) => {
        console.log("onFailureSendNotif", response, params);
        params.callback(false);
        dispatch(alertPopupAction(true, "Notification Not Sent", "danger"));
    }
}

/*--------------- Save Levels API Call ------------*/
const saveLevels = (callback) => {
  return (dispatch, getState) => {
    dispatch(
      alertPopupAction(false, "", getState().generalConfig.alertPopup.color)
    );
    let params = {
      method: "GET",
      path: "levels",
      header: {
        access_token: getState().user.user.authToken,
      },
      callback,
    };
    dispatch(apiWithCallbacks(params, onSuccessSaveLevels, onFailureSaveLevels));
  };
};
const onSuccessSaveLevels = (response, params) => {
  return (dispatch) => {
    console.log("onSuccessSaveLevels", response, params);
    params.callback(response.data);
    if (_.isString(response.data) && !_.isEmpty(response.data))
      dispatch(alertPopupAction(true, response.data, "success"));
    else dispatch(alertPopupAction(true, "Level Save Successfully", "success"));
  };
};
const onFailureSaveLevels = (response, params) => {
  return (dispatch) => {
    console.log("onFailureSaveLevels", response, params);
    params.callback(false);
    dispatch(alertPopupAction(true, "Failed to Save Levels", "danger"));
  };
};


/*--------------- Fetch Levels from MongoDB API Call ------------*/
const getLevels = (callback) => {
  return (dispatch, getState) => {
    dispatch(
      alertPopupAction(false, "", getState().generalConfig.alertPopup.color)
    );
    let params = { method: "GET", path: "getLevels", header: { access_token: getState().user.user.authToken }, callback };
    dispatch(apiWithCallbacks(params, onSuccessGetLevels, onFailureGetLevels));
  };
};
const onSuccessGetLevels = (response, params) => {
  return (dispatch) => {
    console.log("onSuccessGetLevels", response, params);
    params.callback(response.data);
  };
};
const onFailureGetLevels = (response, params) => {
  return (dispatch) => {
    console.log("onFailureGetLevels", response, params);
    params.callback(false);
  };
};

/*--------------- Count Users Based On Levels and Sessions ------------*/
const countUsers = (selectLevel, selectSessions, selectAllLevel, callback) => {
  return (dispatch, getState) => {
     let params = {};
     if (!_.isNil(selectLevel)) params.selectLevel = selectLevel;
     if (!_.isNil(selectSessions)) params.selectSessions = selectSessions;
     if (!_.isNil(selectAllLevel)) params.selectAllLevel = selectAllLevel;
    let data = {
      method: "GET",
      path: "countusers",
      header: {
        access_token: getState().user.user.authToken,
      },
      params,
      callback,
    };
    dispatch(apiWithCallbacks(data, onSuccessCount, onFailureCount));
  };
};
const onSuccessCount = (response, params) => {
  return (dispatch) => {
    console.log("onSuccessCount", response, params);
    params.callback(response.data);
    if (_.isString(response.data) && !_.isEmpty(response.data))
      dispatch(alertPopupAction(true, response.data, "success"));
    else
      dispatch(alertPopupAction(true, "Users Count  Successfully", "success"));
  };
};
const onFailureCount = (response, params) => {
  return (dispatch) => {
    console.log("onFailureCount", response, params);
    params.callback(false);
    dispatch(alertPopupAction(true, "Users Count Failed", "danger"));
  };
};

/*--------------- Verify Email from MongoDB API Call ------------*/
const allEmails = (callback) => {
  return (dispatch, getState) => {
    let params = {
      method: "GET",
      path: "allemails",
      header: {
        access_token: getState().user.user.authToken,
      },
      callback,
    };
    dispatch(apiWithCallbacks(params, onSuccessAllEmail, onFailureAllEmail));
  };
};
const onSuccessAllEmail = (response, params) => {
  return (dispatch) => {
    console.log("onSuccessAllEmail", response, params);
    params.callback(response.data);
  };
};
const onFailureAllEmail = (response, params) => {
  return (dispatch) => {
    console.log("onFailureAllEmail", response, params);
    params.callback(false);
  };
};