import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import HeaderLinks from "./HeaderLinks.jsx";
import Button from "components/CustomButtons/Button.jsx";

import headerStyle from "assets/jss/material-dashboard-react/components/headerStyle.jsx";

function Header({ ...props }) {
    function makeBrand() {
        var name;
        props.routes.map((prop, key) => {
            if (prop.path === props.location.pathname) {
                name = prop.navbarName;
            }
            return null;
        });
        return name;
    }
    const { classes, color, drawerOpen } = props;
    const appBarClasses = classNames({
        [" " + classes[color]]: color
    });
    return (
        <AppBar className={classes.appBar + appBarClasses}>
            <Toolbar className={classes.container}>
                <div className={classes.flex}>
                    {/* Here we create navbar brand, based on route name */}
                    <Hidden smDown implementation="css">
                        {!drawerOpen &&
                            <IconButton
                                color="primary"
                                aria-label="Open drawer"
                                onClick={props.toggleDrawer}
                            >
                                <Menu />
                            </IconButton>
                        }
                        <Button color="transparent" href="#" className={classes.title}>
                            {makeBrand()}
                        </Button>
                    </Hidden>
                    <Hidden mdUp implementation="css">
                        <Button color="transparent" href="#" className={classes.title}>
                            {makeBrand()}
                        </Button>
                    </Hidden>
                </div>
                <Hidden smDown implementation="css">
                    <HeaderLinks history={props.history} logout={props.logout} userName={props.user.user.firstName}/>
                </Hidden>
                <Hidden mdUp implementation="css">
                    <IconButton
                        color="primary"
                        aria-label="open drawer"
                        onClick={props.handleDrawerToggle}
                    >
                        <Menu />
                    </IconButton>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
}

Header.propTypes = {
    classes: PropTypes.object.isRequired,
    color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"])
};

export default withStyles(headerStyle)(Header);