import React from "react";
import classNames from "classnames";
import {NavLink} from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {
    MenuItem, MenuList, Grow, Paper, ClickAwayListener, Hidden, Popper, Divider, Icon, 
    ListItemIcon, ListItemText
} from "@material-ui/core";
import _ from "lodash";
// @material-ui/icons
import ExitToApp from "@material-ui/icons/ExitToApp";
import VpnKey from "@material-ui/icons/VpnKey";
import AccountCircle from "@material-ui/icons/AccountCircle";
// core components
import Button from "components/CustomButtons/Button.jsx";

import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";

class HeaderLinks extends React.Component {
    state = {
        open: false
    };
    handleToggle = () => {
        this.setState(state => ({ open: !state.open }));
    };

    handleClose = event => {
        if (this.anchorEl.contains(event.target)) {
            return;
        }

        this.setState({ open: false });
    };
    logout = () => {
        this.props.logout((status) => {
            if (status) this.props.history.push("/login");
        });
    }

    render() {
        const { classes, userName } = this.props;
        const { open } = this.state;
        return (
            <div>
                <div className={classes.manager}>
                    <Popper
                        open={open}
                        anchorEl={this.anchorEl}
                        transition
                        disablePortal
                        className={
                            classNames({ [classes.popperClose]: !open }) +
                            " " +
                            classes.pooperNav
                        }
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                id="menu-list-grow"
                                style={{
                                    transformOrigin:
                                        placement === "bottom" ? "center top" : "center bottom"
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={this.handleClose}>
                                        <MenuList role="menu">
                                            <MenuItem
                                                // onClick={this.handleClose}
                                                // className={classes.dropdownItem}
                                                disableGutters={true}
                                            >
                                                <ListItemIcon>
                                                    <AccountCircle style={{color: window.innerWidth > 959 ? "inherit" : "#fff"}}/>
                                                </ListItemIcon>
                                                <ListItemText primary={_.upperFirst(userName)} secondary="online" className={classes.dropdownListText} classes={{ primary: classes.dropdownText, secondary: classes.dropdownText}} />
                                            </MenuItem>
                                            <Divider/>
                                            <NavLink to="/changepassword">
                                                <MenuItem
                                                    onClick={this.handleClose}
                                                    // className={classes.dropdownItem}
                                                    disableGutters={true}
                                                >
                                                    <ListItemIcon>
                                                        <VpnKey style={{color: window.innerWidth > 959 ? "inherit" : "#fff"}}/>
                                                    </ListItemIcon>
                                                    <ListItemText primary="Change Password" className={classes.dropdownListText} classes={{ primary: classes.dropdownText }} />
                                                </MenuItem>
                                            </NavLink>
                                            <MenuItem
                                                onClick={this.logout}
                                                // className={classes.dropdownItem}
                                                disableGutters={true}
                                            >
                                                <ListItemIcon>
                                                    <ExitToApp style={{color: window.innerWidth > 959 ? "inherit" : "#fff"}}/>
                                                </ListItemIcon>
                                                <ListItemText primary="Sign Out" className={classes.dropdownListText} classes={{ primary: classes.dropdownText }}/>
                                            </MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </div>
                <Button
                    buttonRef={node => {
                        this.anchorEl = node;
                    }}
                    color={window.innerWidth > 959 ? "transparent" : "white"}
                    justIcon={window.innerWidth > 959}
                    simple={!(window.innerWidth > 959)}
                    aria-label="Person"
                    aria-owns={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    className={classes.buttonLink}
                    onClick={this.handleToggle}
                >   
                    <Hidden smDown implementation="css">
                        <Icon style={{ fontSize: 30 }}>account_circle</Icon>
                    </Hidden>
                    <Hidden mdUp implementation="css">
                        <AccountCircle />
                    </Hidden>
                    <Hidden mdUp implementation="css">
                        <p className={classes.linkText}>Profile</p>
                    </Hidden>
                </Button>
            </div>
        );
    }
}

export default withStyles(headerLinksStyle)(HeaderLinks);
