import _ from "lodash";
import {apiWithCallbacks} from "./../../helpers/apiManager";
import {usersList, setRowsPerPage, totalUserRecords} from "./../../redux/actions/appData";
import {getAllCountries} from "./../Country/functions";
import {alertPopupAction} from "./../../redux/actions/generalConfig";

export const mapStateToProps = (state) => {
    return {
        users: state.appData.users,
        countries: state.appData.countries,
        rowsPerPage: state.appData.rowsPerPage,
        totalUsers: state.appData.totalUserRecords
    }
}
export const mapDispatchToProps = (dispatch) => {
    return {
        getAllUsers: (limit, skip, searchText, searchBy, callback) => dispatch(getAllUsers(limit, skip, searchText, searchBy, callback)),
        updateUser: (data, userId, callBack) => dispatch(updateUser(data, userId, callBack)),
        getUserCourses: (userId, callBack) => dispatch(getUserCourses(userId, callBack)),
        syncUserCourses: (userId, callBack) => dispatch(syncUserCourses(userId, callBack)),
        setRowsPerPage: rows => dispatch(setRowsPerPage(rows)),
        getEmptyCourseListUsers: (limit, skip, callback) => dispatch(getEmptyCourseListUsers(limit, skip, callback)),
        deleteUserId: (userId, callBack) => dispatch(deleteUserId(userId, callBack)),
    }
}

/*-------------- Get All Users --------------*/
export const getAllUsers = (limit, skip, searchText, searchBy, callback) => {
    return (dispatch, getState) => {
        let params = {};
        if(!_.isNil(limit)) params.limit = limit;
        if(!_.isNil(skip)) params.skip = skip;
        if (!_.isNil(searchBy) && !_.isNil(searchText)){
            params.column = searchBy;
            params.colValue = searchText;
        }
        const data = {
            method: "GET",
            path: "users",
            header: {
                access_token: getState().user.user.authToken
            },
            params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessGetUsers, onFailureGetUsers));
    }
}
const onSuccessGetUsers = (response, params) => {
    return (dispatch, getState) => {
        console.log("onSuccessGetUsers", response, params);
        const users = _.map(response.data.users, user => ({ ...user, name: `${user.firstName} ${user.lastName}` }));
        dispatch(usersList(users));
        dispatch(totalUserRecords(response.data.totalUsers));
        if (_.isEmpty(getState().appData.countries)){
            dispatch(getAllCountries());
        }
        if (!_.isNil(params.callback) && _.isFunction(params.callback)) {
            params.callback();
        }
    }
}
const onFailureGetUsers = (response, params) => {
    return (dispatch) => {
        console.log("onFailureGetUsers", response, params);
        if (!_.isNil(params.callback) && _.isFunction(params.callback)) {
            params.callback();
        }
    }
}

/*---------- Update User Details -----------*/
const updateUser = (data, userId, callBack) => {
    return (dispatch, getState) => {
        dispatch(alertPopupAction(false, "", getState().generalConfig.alertPopup.color));
        let params = {
            method: "PATCH",
            path: `users/${userId}`,
            data,
            header: {
                access_token: getState().user.user.authToken
            },
            callBack
        }
        dispatch(apiWithCallbacks(params, onSuccessUpdateUser, onFailureUpdateUser));
    }
}
const onSuccessUpdateUser = (response, params) => {
    return (dispatch, getState) => {
        console.log("onSuccessUpdateUser", response, params);
        let userIndex = _.findIndex(getState().appData.users, user => user.id === response.data.id);
        let users = _.assign([...getState().appData.users], {[userIndex]: {...response.data, name: `${response.data.firstName} ${response.data.lastName}`}});
        dispatch(usersList(users));
        params.callBack(true);
        dispatch(alertPopupAction(true, "User Updated Successfully", "success"));
    }
}
const onFailureUpdateUser = (response, params) => {
    return (dispatch) => {
        console.log("onFailureUpdateUser", response, params);
        params.callBack(false);
        dispatch(alertPopupAction(true, "User Not Updated", "danger"));
    }
}

/*--------------- Get User Courses ----------------*/
const getUserCourses = (userId, callBack) => {
    return (dispatch, getState) => {
        let params = {
            method: "GET",
            path: `users/${userId}/courses`,
            header: {
                access_token: getState().user.user.authToken
            },
            callBack
        }
        dispatch(apiWithCallbacks(params, onSuccessGetUserCourses, onFailureGetUserCourses));
    }
}
const onSuccessGetUserCourses = (response, params) => {
    return (dispatch) => {
        console.log("onSuccessGetUserCourses" ,response, params);
        const courses = _.map(response.data, course => course.title);
        params.callBack(courses);
    }
}
const onFailureGetUserCourses = (response, params) => {
    return (dispatch) => {
        console.log("onFailureGetUserCourses", response, params);
        params.callBack([]);
    }
}

/*----------------- Sync User Courses -----------------*/
const syncUserCourses = (userId, callBack) => {
    return (dispatch, getState) => {
        dispatch(alertPopupAction(false, "", getState().generalConfig.alertPopup.color));
        let params = {
            method: "GET",
            path: `users/${userId}/courses/sync`,
            header: {
                access_token: getState().user.user.authToken
            },
            callBack
        }
        dispatch(apiWithCallbacks(params, onSuccessSyncCourses, onFailureSyncCourses));
    }
}
const onSuccessSyncCourses = (response, params) => {
    return (dispatch) => {
        console.log("onSuccessSyncCourses", response, params);
        params.callBack();
        dispatch(alertPopupAction(true, "User Courses Sync Successfully", "success"));
    }
}
const onFailureSyncCourses = (response, params) => {
    return (dispatch) => {
        console.log("onFailureSyncCourses", response, params);
        params.callBack();
        dispatch(alertPopupAction(true, "User Courses Sync Failed", "danger"));
    }
}

/*----------------- Deleted User -----------------*/
const deleteUserId = (userId, callBack) => {
  return (dispatch, getState) => {
    let params = {
      method: "GET",
      path: `users/${userId}/delete`,
      header: {
        access_token: getState().user.user.authToken,
      },
      callBack,
    }
    dispatch(apiWithCallbacks(params, onSuccessDeleteUser, onFailureDeleteUser));
  }
}
const onSuccessDeleteUser = (response, params) => {
  return (dispatch) => {
    console.log("onSuccessDeleteUser", response, params);
    params.callBack();
    dispatch(alertPopupAction(true, "User Deleted Successfully", "success"));
  }
}
const onFailureDeleteUser = (response, params) => {
  return (dispatch) => {
    console.log("onFailureDeleteUser", response, params);
    params.callBack();
    dispatch(alertPopupAction(true, "User Deleted Failed", "danger"));
  }
}

/*----------------- Get users with empty course list -----------------*/
export const getEmptyCourseListUsers = (limit, skip, callback) => {
    return (dispatch, getState) => {
        let params = {};
        if(!_.isNil(limit)) params.limit = limit;
        if(!_.isNil(skip)) params.skip = skip;
        let data = {
            method: "GET",
            path: `users/null`,
            header: {
                access_token: getState().user.user.authToken
            },
            params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessGetNullUsers, onFailureGetNullUsers));
    }
}
const onSuccessGetNullUsers = (response, params) => {
    return (dispatch, getState) => {
        console.log("onSuccessGetNullUsers", response, params);
        const users = _.map(response.data.users, user => ({ ...user, name: `${user.firstName} ${user.lastName}` }));
        dispatch(usersList(users));
        dispatch(totalUserRecords(response.data.totalUsers));
        // if (_.isEmpty(getState().appData.countries)){
        //     dispatch(getAllCountries());
        // }
        if (!_.isNil(params.callback) && _.isFunction(params.callback)) {
            params.callback();
        }
    }
}
const onFailureGetNullUsers = (response, params) => {
    return (dispatch) => {
        console.log("onFailureGetNullUsers", response, params);
        if (!_.isNil(params.callback) && _.isFunction(params.callback)) {
            params.callback();
        }
    }
}