import _ from "lodash";
import { apiWithCallbacks } from "./../../helpers/apiManager";
import { alertPopupAction } from "./../../redux/actions/generalConfig";
import { welcomeMessage } from "./../../redux/actions/appData";

export const mapStateToProps = state => {
  return {
    welcomeMessage: state.appData.welcomeMessage
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    getWelcomeMessage: cb => dispatch(getWelcomeMessage(cb)),
    setWelcomeMessage: (data, cb) => dispatch(setWelcomeMessage(data, cb))
  };
};

/*--------------- Send Notification ApiCall ------------*/
const getWelcomeMessage = (callback) => {
  return (dispatch, getState) => {
    dispatch(
      alertPopupAction(false, "", getState().generalConfig.alertPopup.color)
    );
    let params = {
        method: 'GET',
        path: 'welcomes',
        header: {
            access_token: getState().user.user.authToken
        },
        callback
    };
    dispatch(apiWithCallbacks(params, onSuccessWelcomeMessage, onFailureWelcomeMessage));
  };
};
const setWelcomeMessage = (data, callback) => {
    return (dispatch, getState) => {
        dispatch(
            alertPopupAction(false, "", getState().generalConfig.alertPopup.color)
        );
        let params = {
            method: 'POST',
            path: 'welcomes',
            data,
            header: {
                access_token: getState().user.user.authToken
            },
            callback
        }
        dispatch(apiWithCallbacks(params, onSuccessWelcomeMessage, onFailureWelcomeMessage));
    }
}
const onSuccessWelcomeMessage = (response, params) => {
    return dispatch => {
        console.log("TCL: onSuccessWelcomeMessage -> response", response)
        if(response.data && _.isArray(response.data) && response.data.length > 0) {
            dispatch(welcomeMessage(response.data[0]));
            if(_.has(params, "callback") && _.isFunction(params.callback)) {
                params.callback(true);
            }
        } else if (response.data && _.isObject(response.data)) {
            dispatch(welcomeMessage(response.data));
            if(_.has(params, "callback") && _.isFunction(params.callback)) {
                params.callback(true);
            }
        }
        if(params.method == "POST") {
            dispatch(alertPopupAction(true, "Welcome message updated", "success"));
        }
    }
}
const onFailureWelcomeMessage = (response, params) => {
    return dispatch => {
        console.log("TCL: onFailureWelcomeMessage -> response", response, params);
        dispatch(alertPopupAction(true, "Error in setting welcome message", "danger"));
        if(_.has(params, "callback") && _.isFunction(params.callback)) params.callback(false);
    };
}