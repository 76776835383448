// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Dashboard from "@material-ui/icons/Dashboard";
import DashboardPage from "views/Dashboard/Dashboard.jsx";
import UserProfile from "views/UserProfile/UserProfile.jsx";
import Users from './../views/Users/Users.jsx';
import Notification from './../views/Notification/Notification.jsx';
import Feedback from './../views/Feedback/Feedback.jsx';
import Videos from './../views/Videos/Videos.jsx';
import Country from './../views/Country/Country.jsx';
import Courses from './../views/Courses/Courses.jsx';
import Quizzes from './../views/Quizzes/Quizzes.jsx';
import ChangePassword from './../views/ChangePassword/ChangePassword.jsx';
import WelcomeIcon from '@material-ui/icons/Message';
import WelcomeMessage from "./../views/WelcomeMessage/WelcomeMessage.jsx";
import UpgradeMessage from "./../views/UpgradeMessage/UpgradeMessage.jsx";
import TermsAndConditions from "../views/TermsAndConditions/TermsAndConditions.jsx"
// import Typography from "views/Typography/Typography.jsx";
// import Icons from "views/Icons/Icons.jsx";

const dashboardRoutes = [
  {
    path: "/dashboard",
    sidebarName: "Dashboard",
    navbarName: "Material Dashboard",
    icon: Dashboard,
    component: DashboardPage
  },
  {
    path: "/users",
    sidebarName: "Users",
    navbarName: "Users",
    icon: "people",
    component: Users
  },
  {
    path: "/notification",
    sidebarName: "Notification",
    navbarName: "Notification",
    icon: "send",
    component: Notification
  },
  {
    path: "/feedback",
    sidebarName: "Feedback",
    navbarName: "Feedback",
    icon: "feedback",
    component: Feedback
  },
  {
    path: "/videos",
    sidebarName: "Helpful Material",
    navbarName: "Videos",
    icon: "video_library",
    component: Videos
  },
  {
    path: "/country",
    sidebarName: "Country",
    navbarName: "Country",
    icon: "map",
    component: Country
  },
  {
    path: "/courses",
    sidebarName: "Courses",
    navbarName: "Courses",
    icon: "collections_bookmark",
    component: Courses
  },
  {
    path: "/quizzes",
    sidebarName: "Quizzes",
    navbarName: "Quizzes",
    icon: "collections_bookmark",
    component: Quizzes
  },
  {
    path: "/welcomemessage",
    sidebarName: "Welcome Message",
    navbarName: "Welcome Message",
    icon: WelcomeIcon,
    component: WelcomeMessage
  },
  {
    path: "/userprofile",
    sidebarName: "User Profile",
    navbarName: "Profile",
    icon: Person,
    component: UserProfile
  },
  {
    path: "/changepassword",
    sidebarName: "",
    navbarName: "Change Password",
    component: ChangePassword
  },
  {
	path: "/upgrademessage",
	sidebarName: "Upgrade Message",
	nabarName: "Upgrade Message",
	icon: WelcomeIcon,
	component: UpgradeMessage
  },
  {
    path: "/termsandconditions",
    sidebarName: "Terms And Conditions",
    navbarName: "Terms And Conditions",
    icon: WelcomeIcon,
    component: TermsAndConditions
  },
  { redirect: true, path: "/", to: "/users", navbarName: "Redirect" }
];

export default dashboardRoutes;
