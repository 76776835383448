import { createStore, applyMiddleware } from 'redux';
import reducers from './reducers/index.js';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {createLogger} from "redux-logger";

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['generalConfig']
}

const loggerMiddleware = createLogger({collapsed: true, duration: true});
const middleware = applyMiddleware(thunk, loggerMiddleware);

const persistedReducer = persistReducer(persistConfig, reducers);

export const appStore = createStore(persistedReducer, middleware);
export const persistor = persistStore(appStore);
