import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {
    FormControl, InputLabel, Input, InputAdornment, IconButton, MenuItem, Select, Dialog, Grid, FormHelperText
} from "@material-ui/core";
import _ from "lodash";
import moment from "moment";
import {connect} from "react-redux";
import {Formik} from "formik";
import Spinner from "react-spinner-material";
import Search from "@material-ui/icons/Search";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "components/Table/Table.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from './../../components/CustomButtons/Button';
import {mapStateToProps, mapDispatchToProps} from "./functions";
import {validateEmail} from "./../../helpers/helpers";
import { CSVLink } from "react-csv";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "400",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    textField: {
        marginTop: 0
    },
    modalGrid: {
        padding: 15
    },
    headTextGrid: {
        padding: 5
    },
    userTextGrid: {
        padding: 5
    },
    userText: {
        margin: 0,
        color: "#000000",
        fontWeight: "400"
    },
    emptyListMsg: {
        paddingTop: 20, 
        paddingBottom: 20, 
        margin: 0, 
        color: "#0093cb"
    }
};

class Users extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            filterBy: "general",
            searchText: "",
            selectedUser: undefined,
            showModal: false,
            page: 0,
            loading: true,
            emptyUsers: false,
            throttleFunc: _.throttle(this.getUserCall, 1000, { 'trailing': true })
        }
    }
    componentDidMount(){
        const { page, searchText, filterBy } = this.state;
        const { rowsPerPage } = this.props;
        this.props.getAllUsers(rowsPerPage, page * rowsPerPage, searchText, filterBy, (res) => {
            this.setState({ loading: false })
        });
    }
    componentDidUpdate(prevProps, prevState){
        const { page, searchText, filterBy } = this.state;
        const { rowsPerPage } = this.props;
        if (prevState.page !== page || prevProps.rowsPerPage !== rowsPerPage){
            this.setState({loading: true});
            this.props.getAllUsers(rowsPerPage, page * rowsPerPage, searchText, filterBy, (res) => {
                this.setState({ loading: false })
            });
        }
        else if(prevState.filterBy !== filterBy){
            this.setState({ loading: true });
            this.props.getAllUsers(rowsPerPage, page * rowsPerPage, searchText, filterBy, (res) => {
                this.setState({ loading: false })
            });
        }
        else if(prevState.searchText !== searchText){
            // this.state.throttleFunc();
        }
    }
    searchClick = () => {
        this.state.throttleFunc();
    }
    getUserCall = () => {
        const { page, searchText, filterBy } = this.state;
        const { rowsPerPage } = this.props;
        this.setState({ loading: true });
        this.props.getAllUsers(rowsPerPage, page * rowsPerPage, searchText, filterBy, (res) => {
            this.setState({ loading: false });
        });
    }
    handleChangePage = (event, page) => {
        this.setState({ page, emptyUsers: false });
    }
    handleChangeRowsPerPage = event => {
        this.setState({ page: 0, emptyUsers: false});
        this.props.setRowsPerPage(event.target.value);
    }
    searchByChange = event => {
        this.setState({ filterBy: event.target.value, emptyUsers: false });
    }
    searchTextChange = event => {
        this.setState({ searchText: event.target.value, emptyUsers: false})
    }
    userSelected = userId => {
        const selectedUser = _.find(this.props.users, (user) => user.id === userId);
        if (!_.isNil(selectedUser)){
            this.setState({
                selectedUser,
                showModal: true
            });
        }
    }
    updateUser = (values, { setSubmitting }) => {
        let {id, firstName, lastName, email, platform, isActive, country} = values;
        let countryObj = _.find(this.props.countries, obj => obj.name === country);
        let countryId = "";
        if(!_.isUndefined(countryObj)) countryId = countryObj.id;
        let data = {
            firstName, lastName, email, platform, isActive,
            country: countryId
        }
        this.props.updateUser(data, id, (status) => {setSubmitting(false); status && this.setState({ showModal: false})});
    }
    syncUserCourses = (userId, cb) => {
        this.props.syncUserCourses(userId, cb);
    }
    deleteUserId = (userId, cb) => {
        this.props.deleteUserId(userId, cb);
    }
    switchUsers = () => {
        this.setState({loading: true});
        const { page, searchText, filterBy } = this.state;
        const { rowsPerPage } = this.props;
        if(this.state.emptyUsers){
            this.props.getAllUsers(rowsPerPage, page * rowsPerPage, searchText, filterBy, (res) => {
                this.setState({ loading: false, emptyUsers:false })
            });
        }else{
            this.props.getEmptyCourseListUsers(rowsPerPage, page * rowsPerPage, (res) => {
                this.setState({loading:false, emptyUsers:true})
            });
        }
    }
    exportUsers = (users) => {
        let newUsers = _.cloneDeep(users);
        return _.map(newUsers, user => {
            user.createdAt = moment(user.createdAt).format("MM-DD-YYYY HH:mm:ss");
            user.lastSeenAt = moment(user.lastSeenAt).format("MM-DD-YYYY HH:mm:ss");
            user.updatedAt = moment(user.updatedAt).format("MM-DD-YYYY HH:mm:ss");
            delete user['authToken'];
            delete user['oneSignalId'];
            delete user['avatarUrl'];
            user['country'] = user['country'] ? user['country'].name : undefined;
            delete user['id'];
            return user;
        });
    }
    render(){
        const { classes, users, rowsPerPage, totalUsers } = this.props;
        const { searchText, selectedUser, page, loading } = this.state;
        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className={classes.cardTitleWhite}>Users</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer justify="flex-end">
                                <GridItem xs={12} sm={4} md={3}>
                                    <FormControl fullWidth>
                                        <Button type="submit" color="primary" size="lg" onClick={this.switchUsers}>{this.state.emptyUsers ? "Show All Users":"Users with Empty Course List" }</Button>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={4} md={3}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="search">Search</InputLabel>
                                        <Input
                                            id="search"
                                            placeholder="Search"
                                            value={searchText}
                                            onChange={this.searchTextChange}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton color="primary" onClick={this.searchClick}>
                                                        <Search />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={3} md={2}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="searchBy">Search By</InputLabel>
                                        <Select
                                            value={this.state.filterBy}
                                            onChange={this.searchByChange}
                                            inputProps={{
                                                name: 'search',
                                                id: 'searchBy',
                                            }}
                                        >
                                            <MenuItem value="general">General</MenuItem>
                                            <MenuItem value="firstName">First Name</MenuItem>
                                            <MenuItem value="lastName">Last Name</MenuItem>
                                            <MenuItem value="email">Email</MenuItem>
                                            {/* <MenuItem value="country">Country</MenuItem> */}
                                            <MenuItem value="platform">Platform</MenuItem>
                                            <MenuItem value="accountType">Network</MenuItem>
                                        </Select>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={1} md={1}>
                                    <CSVLink data={this.exportUsers(users)}>
                                        <IconButton color="primary" aria-label="upload picture" component="span">
                                            <CloudDownloadIcon />
                                        </IconButton>
                                    </CSVLink>
                                </GridItem>
                            </GridContainer>
                            {loading
                                ?
                                <GridContainer justify="center">
                                    <Spinner size={50} spinnerColor="#0093cb" spinnerWidth={5} visible={true} />
                                </GridContainer>
                                :
                                !_.isEmpty(users)
                                    ?
                                    <Table
                                        tableHeaderColor="primary"
                                        tableHead={tableHeadings}
                                        tableData={users}
                                        onRowClick={this.userSelected}
                                        page={page}
                                        rowsPerPage={rowsPerPage}
                                        handleChangePage={this.handleChangePage}
                                        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                                        totalNumber={totalUsers}
                                    />
                                    :
                                    <GridContainer justify="center">
                                        <h4 className={classes.emptyListMsg}>No User Found</h4>
                                    </GridContainer>
                            }
                        </CardBody>
                    </Card>
                    {!_.isUndefined(selectedUser) && this.state.showModal &&
                        <UserDetails
                            open={this.state.showModal}
                            selectedUser={selectedUser}
                            onClose={() => this.setState({showModal: false})}
                            updateUser={this.updateUser}
                            countries={this.props.countries}
                            getUserCourses={this.props.getUserCourses}
                            syncUserCourses={this.syncUserCourses}
                            deleteUserId={this.deleteUserId}
                        />
                    }
                </GridItem>
            </GridContainer>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Users));

class UserDetailsModal extends React.PureComponent{
    constructor(props){
        super(props);
        this.state = {
            userDetails: {},
            isActive: props.selectedUser.isActive,
            editableFields: ["firstName", "lastName", "country", "isActive"],
            loading: true,
            courseSyncing: false,
            delete_userID: false
        }
    }
    componentDidMount(){
        if (!_.isEmpty(this.state.userDetails.id)){
            this.props.getUserCourses(this.state.userDetails.id, (courses) => {
                this.setState(state => {
                    const userDetails = _.assign(state.userDetails, { courses: courses.sort(this.sortAlphaNum) })
                    return {userDetails, loading: false}
                })
            });
        }
    }
    static getDerivedStateFromProps(props, state){
        let obj = _.pick(props.selectedUser, [
            "id", "name", "firstName", "lastName", "email", "year", "level", "session", "accountType", "country",
            "isActive", "platform", "createdAt", "updatedAt", "lastLoggedInAt"]);
        obj.country = obj.country && obj.country.name;
        if(_.isUndefined(obj.year)){
            obj.year = "N/A";
        }
        if(_.isUndefined(obj.level)){
            obj.level = "N/A";
        }
        if(_.isUndefined(obj.session)){
            obj.session = "N/A";
        }
        const userDetails = _.assign(state.userDetails, obj);
        return {userDetails}
    }

    sortAlphaNum = (a, b) => a.localeCompare(b, 'en', { numeric: true })

    syncCourses = () => {
        this.setState({ courseSyncing: true });
        this.props.syncUserCourses(this.props.selectedUser.id, () => this.setState({ courseSyncing: false }));
    }
    deleteUser = () => {
        this.setState({ delete_userID: true });
        this.props.deleteUserId(this.props.selectedUser.id, () => this.setState({ delete_userID: false }));
    }
    renderFields = (values, key, errors, touched, handleChange, handleBlur, countries, classes) => {
        switch(key){
            case "isActive":
                return(
                    <FormControl fullWidth error={errors[key] && touched[key]}>
                        <Select
                            name={key}
                            value={values[key]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={classes.userText}
                        >
                            <MenuItem value={true}>True</MenuItem>
                            <MenuItem value={false}>False</MenuItem>
                        </Select>
                    </FormControl>
                );
            case "country":
                return(
                    <FormControl fullWidth error={errors[key] && touched[key]}>
                        <Select
                            name={key}
                            value={values[key] ? values[key] : ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={classes.userText}
                        >
                            {_.map(countries, country => <MenuItem value={country.name} key={country.id}>{country.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                );
            case "platform":
                return(
                    <FormControl fullWidth error={errors[key] && touched[key]}>
                        <Select
                            name={key}
                            value={values[key]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={classes.userText}
                        >
                            <MenuItem value="android">Android</MenuItem>
                            <MenuItem value="ios">iOS</MenuItem>
                        </Select>
                    </FormControl>
                );
            default:
                return(
                    <FormControl fullWidth>
                        <Input
                            name={key}
                            placeholder={_.startCase(key)}
                            value={values[key]}
                            fullWidth={true}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors[key] && touched[key]}
                            className={classes.userText}
                        />
                        {(errors[key] && touched[key]) && <FormHelperText error={true}>{errors[key]}</FormHelperText>}
                    </FormControl>
                );
        }
    }
    render(){
        const { classes, countries } = this.props;
        const { userDetails, editableFields, loading, courseSyncing, delete_userID } = this.state;
        if(loading){
            return(
                <Dialog open={this.props.open} onClose={this.props.onClose} maxWidth="sm">
                    <Grid container className={classes.modalGrid}>
                        <Spinner size={50} spinnerColor="#0093cb" spinnerWidth={5} visible={true} />
                    </Grid>
                </Dialog>
            )
        }
        return(
            <Dialog open={this.props.open} onClose={this.props.onClose} maxWidth="sm">
                <Formik
                    initialValues={userDetails}
                    validate={values => {
                        let errors = {};
                        _.forEach(values, (value,key) => (!value && _.isString(value)) ? (errors[key] = "Required") : null);
                        if (!_.isNil(validateEmail(values.email))) errors.email = validateEmail(values.email);
                        if (_.isNil(values.country)) errors.country = "Required";
                        return errors
                    }}
                    onSubmit={this.props.updateUser}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                    }) => (
                        <Grid container className={classes.modalGrid}>
                            {_.map(userDetails, (value, key) => {
                                if(key !== "id"){
                                    return(
                                        <Grid container item key={key}>
                                            <Grid container item xs={5} sm={5} md={5} justify="flex-end" alignItems="center" className={classes.headTextGrid}>
                                                <p className={classes.userText} style={{ color: "rgb(94, 94, 94)", alignSelf: key === "courses" ? "flex-start" : "auto" }}>{`${_.startCase(key)} :`}</p>
                                            </Grid>
                                            <Grid container item xs={7} sm={7} md={7} className={classes.userTextGrid} alignItems="center" >
                                                {_.isArray(value)
                                                    ?
                                                    _.isEmpty(value)
                                                        ? <p className={classes.userText}>None</p>
                                                        : _.map(value, (val, i) => <p key={i} className={classes.userText} style={{color:"#000"}}>{val}</p>)
                                                    :
                                                    _.includes(editableFields, key)
                                                        ? 
                                                        this.renderFields(values, key, errors, touched, handleChange, handleBlur, countries, classes)
                                                        :
                                                        (key === "createdAt" || key === "updatedAt")
                                                            ? <p className={classes.userText}>{moment(value).format("DD MMM YYYY, hh:mm a")}</p> 
                                                            : <p className={classes.userText}>{value+""}</p>
                                                }
                                            </Grid>
                                        </Grid>
                                    )
                                }
                            })}    
                            <Grid container item justify="flex-end">
                                <Button color="danger" size="sm" onClick={() => { if (window.confirm('Are you sure you want to delete?')) {this.deleteUser()}  } } disabled={delete_userID}>Delete User</Button>
                                <Button color="primary" size="sm" onClick={handleSubmit} disabled={isSubmitting}>Save Changes</Button>
                                <Button color="primary" size="sm" onClick={this.syncCourses} disabled={courseSyncing}>Sync Courses</Button>
                                <Button color="danger" size="sm" onClick={this.props.onClose}>Cancel</Button>
                            </Grid>
                        </Grid>
                    )}
                </Formik>
            </Dialog>
        )
    }
}

const UserDetails = withStyles(styles)(UserDetailsModal);

const tableHeadings = [
    { id: "id", label: "Sr#", sortable: false },
    { id: "name", label: "Name", sortable: true },
    { id: "email", label: "Email", sortable: true },
    { id: "lastSeenAt", label: "Last Login", sortable: false },
    { id: "country", label: "Country", sortable: true },
    { id: "platform", label: "Platform", sortable: true },
    { id: "accountType", label: "Network", sortable: true }
];