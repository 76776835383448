import {apiWithCallbacks} from "./../../helpers/apiManager";
import {logoutUser} from "./../../redux/actions/user";
import {alertPopupAction} from "./../../redux/actions/generalConfig";

export const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}
export const mapDispatchToProps = (dispatch) => {
    return {
        logout: (callback) => dispatch(logout(callback))
    }
}

/*--------------- Logout Api Call -----------*/
const logout = (callback) => {
    return (dispatch, getState) => {
        dispatch(alertPopupAction(false, "", getState().generalConfig.alertPopup.color));
        let params = {
            method: "GET",
            path: "account/logout",
            header: {
                access_token: getState().user.user.authToken
            },
            callback
        }
        dispatch(apiWithCallbacks(params, onSuccessLogout, onFailureLogout));
    }
}
const onSuccessLogout = (response, params) => {
    return (dispatch) => {
        console.log("onSuccessLogout", response, params);
        dispatch(logoutUser());
        params.callback(true);
    }
}
const onFailureLogout = (response, params) => {
    return (dispatch) => {
        console.log("onFailureLogout", response, params);
        dispatch(alertPopupAction(true, "Logout Failed", "danger"));
    }
}