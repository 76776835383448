import _ from "lodash";
import {apiWithCallbacks} from "./../../helpers/apiManager";
import {coursesList, setRowsPerPage, totalCourseRecords} from "./../../redux/actions/appData";
import {alertPopupAction} from "./../../redux/actions/generalConfig";

export const mapStateToProps = (state) => {
    return {
        courses: state.appData.courses,
        rowsPerPage: state.appData.rowsPerPage,
        totalCourses: state.appData.totalCourseRecords
    }
}

export const mapDispatchToProps = (dispatch) => {
    return {
        getCourses: (limit, skip, searchText, searchBy, callback) => dispatch(getCourses(limit, skip, searchText, searchBy, callback)),
        syncAllCourses: (callback) => dispatch(syncAllCourses(callback)),
        syncCourse: (courseId, callback) => dispatch(syncCourse(courseId, callback)),
        setRowsPerPage: rows => dispatch(setRowsPerPage(rows))
    }
}

/*---------- Get All Courses ------------*/
const getCourses = (limit, skip, searchText, searchBy, callback) => {
    return (dispatch, getState) => {
        let params = {};
        if(!_.isNil(limit)) params.limit = limit;
        if(!_.isNil(skip)) params.skip = skip;
        if (!_.isNil(searchBy) && !_.isNil(searchText)){
            // const where = {[searchBy]: {contains: searchText}};
            params.where = searchText;
        }
        let data = {
            method: "GET",
            path: "courses",
            header: {
                access_token: getState().user.user.authToken
            },
            params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessGetCourses, onFailureGetCourses));
    }
}
const onSuccessGetCourses = (response, params) => {
    return (dispatch) => {
        console.log("onSuccessGetCourses", response, params);
        let courses = _.map(response.data.courses, course => _.pick(course, ["id", "title", "courseServer"]));
        dispatch(coursesList(courses));
        dispatch(totalCourseRecords(response.data.totalCourses));
        params.callback();
    }
}
const onFailureGetCourses = (response, params) => {
    return (dispatch) => {
        console.log("onFailureGetCourses", response, params);
        params.callback();
    }
}

/*----------- Sync All Courses --------------*/
const syncAllCourses = (callback) => {
    return (dispatch, getState) => {
        dispatch(alertPopupAction(false, "", getState().generalConfig.alertPopup.color));
        let params = {
            method: "GET",
            path: "courses/sync",
            header: {
                access_token: getState().user.user.authToken
            },
            callback
        }
        dispatch(apiWithCallbacks(params, onSuccessSyncAllCourses, onFailureSyncAllCourses));
    }
}
const onSuccessSyncAllCourses = (response, params) => {
    return (dispatch) => {
        console.log("onSuccessSyncAllCourses", response, params);
        params.callback();
        dispatch(alertPopupAction(true, "Course data will be synced in background", "success"));
    }
}
const onFailureSyncAllCourses = (response, params) => {
    return (dispatch) => {
        console.log("onFailureSyncAllCourses", response, params);
        params.callback();
        dispatch(alertPopupAction(true, "All Courses Sync Failed", "danger"));
    }
}

/*------------ Sync Single Course ------------*/
const syncCourse = (courseId, callback) => {
    return (dispatch, getState) => {
        dispatch(alertPopupAction(false, "", getState().generalConfig.alertPopup.color));
        let params = {
            method: "GET",
            path: `courses/${courseId}/sync`,
            header: {
                access_token: getState().user.user.authToken
            },
            callback, courseId
        }
        dispatch(apiWithCallbacks(params, onSuccessSyncCourse, onFailureSyncCourse));
    }
}
const onSuccessSyncCourse = (response, params) => {
    return (dispatch) => {
        console.log("onSuccessSyncCourse", response, params);
        dispatch(alertPopupAction(true, "Course data will be synced in background", "success"));
        params.callback(params.courseId);
    }
}
const onFailureSyncCourse = (response, params) => {
    return (dispatch) => {
        console.log("onFailureSyncCourse", response, params);
        dispatch(alertPopupAction(true, "Courses Sync Failed", "danger"));
        params.callback(params.courseId);
    }
}