import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react'
import {appStore, persistor} from "./redux";
import AlertPopup from "./components/AlertPopup/AlertPopup";

import "assets/css/material-dashboard-react.css?v=1.5.0";

import indexRoutes from "routes/index.jsx";

export const hist = createBrowserHistory();

ReactDOM.render(
    <Provider store={appStore}>
        <PersistGate persistor={persistor}>
            <Router history={hist}>
                <Switch>
                    {indexRoutes.map((prop, key) => {
                        return <Route path={prop.path} component={prop.component} key={key} />;
                    })}
                </Switch>
            </Router>
            <AlertPopup />
        </PersistGate>
    </Provider>
    ,
    document.getElementById("root")
);
