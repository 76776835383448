/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
import {connect} from "react-redux";
import _ from "lodash";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import {mapStateToProps, mapDispatchToProps} from "./function"

import dashboardRoutes from "routes/dashboard.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";
import { drawerWidth } from "./../../assets/jss/material-dashboard-react.jsx";
import logo from "assets/img/ift_logo2.png";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#0093cb'
        },
        default: {
            main: "#cccccc"
        }
    }
},
)

const switchRoutes = (
    <Switch>
        {dashboardRoutes.map((prop, key) => {
            if (prop.redirect)
                return <Redirect from={prop.path} to={prop.to} key={key} />;
            return <Route path={prop.path} component={prop.component} key={key} />;
        })}
    </Switch>
);

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileOpen: false,
            drawerOpen: false
        };
        this.resizeFunction = this.resizeFunction.bind(this);
    }
    handleDrawerToggle = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen, drawerOpen: false });
    };
    toggleDrawer = () => {
        this.setState({ drawerOpen: !this.state.drawerOpen });
    }
    getRoute() {
        return this.props.location.pathname !== "/maps";
    }
    resizeFunction() {
        if (window.innerWidth >= 960) {
            this.setState({ mobileOpen: false });
        }
        else if (window.innerWidth <= 960) this.setState({ drawerOpen: false })
    }
    componentDidMount() {
        console.log("Dashboard Comp Did Mount:", this.props.user)
        if (_.isEmpty(this.props.user.user) && _.isNil(this.props.user.user.authToken) && _.isEmpty(this.props.user.user.authToken)){
            this.props.history.push("/login")
        }
        if (navigator.platform.indexOf("Win") > -1) {
            // const ps = new PerfectScrollbar(this.refs.mainPanel,Options);
        }
        window.addEventListener("resize", this.resizeFunction);
    }
    componentDidUpdate(e) {
        if (e.history.location.pathname !== e.location.pathname) {
            this.refs.mainPanel.scrollTop = 0;
            if (this.state.mobileOpen) {
                this.setState({ mobileOpen: false });
            }
        }
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeFunction);
    }
    render() {
        const { classes, ...rest } = this.props;
        return (
            <MuiThemeProvider theme={theme}>
                <div className={classes.wrapper}>
                    <Sidebar
                        routes={dashboardRoutes}
                        logoText={"IFT Admin"}
                        logo={logo}
                        handleDrawerToggle={this.handleDrawerToggle}
                        toggleDrawer={this.toggleDrawer}
                        open={this.state.mobileOpen}
                        openDrawer={this.state.drawerOpen}
                        color="blue"
                        {...rest}
                    />
                    <div className={classes.mainPanel} style={{ width: this.state.drawerOpen ? `calc(100% - ${drawerWidth}px)` : "100%" }} ref="mainPanel">
                        <Header
                            routes={dashboardRoutes}
                            handleDrawerToggle={this.handleDrawerToggle}
                            toggleDrawer={this.toggleDrawer}
                            drawerOpen={this.state.drawerOpen}
                            {...rest}
                        />
                        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
                        {this.getRoute() ? (
                            <div className={classes.content}>
                                <div className={classes.container}>{switchRoutes}</div>
                            </div>
                        ) : (
                                <div className={classes.map}>{switchRoutes}</div>
                            )}
                        {/* {this.getRoute() ? <Footer /> : null} */}
                    </div>
                </div>
            </MuiThemeProvider>
        );
    }
}

App.propTypes = {
    classes: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(dashboardStyle)(App));