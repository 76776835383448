import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { TextField, Grid, FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@material-ui/core";
// core components
import { connect } from "react-redux";
import _ from "lodash";
import { Formik, Form } from "formik";
import Spinner from "react-spinner-material";
// custom components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "./../../components/CustomButtons/Button";
import { mapStateToProps, mapDispatchToProps } from "./functions";

const styles = {
  cardCategoryWhite: {
	"&,& a,& a:hover,& a:focus": {
	  color: "rgba(255,255,255,.62)",
	  margin: "0",
	  fontSize: "14px",
	  marginTop: "0",
	  marginBottom: "0"
	},
	"& a,& a:hover,& a:focus": {
	  color: "#FFFFFF"
	}
  },
  cardTitleWhite: {
	color: "#FFFFFF",
	marginTop: "0px",
	minHeight: "auto",
	fontWeight: "400",
	fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
	marginBottom: "3px",
	textDecoration: "none",
	"& small": {
	  color: "#777",
	  fontSize: "65%",
	  fontWeight: "400",
	  lineHeight: "1"
	}
  },
  icon: {
	fontSize: 20
  },
  listItem: {
	margin: 0,
	padding: 10,
	alignItems: "flex-start"
  },
  textField: {
	marginTop: 0
  },
  emptyListMsg: {
	paddingTop: 20,
	paddingBottom: 20,
	margin: 0,
	color: "#0093cb"
  }
};

class UpgradeMessage extends React.Component {
  constructor(props) {
	super(props);
	this.state = {
	  loading: false,
	  selectedPlatform: "",
	  platformBlur: false,
	  upgradeMessage: {},
	  title: "",
	  message: ""
	};
  }
  componentDidMount() {
	this.setState({ loading: true });
	this.props.getUpgradeMessage(() => {
		this.setState({ loading: false });
	});
  }
  static getDerivedStateFromProps(props) {
	let upgradeMessage = {};
	props.upgradeMessage.forEach((message) => {
		if (message.plateform && message.id) {
			upgradeMessage[message.plateform] = message;
		}
	});
	return {upgradeMessage};
  }

  setUpgradeMessage = (values, {setSubmitting}) => {
	if (!this.state.selectedPlatform) {
		this.setState({platformBlur: true});
		setSubmitting(false);
		return;
	} else {
		let obj = {
			plateform: this.state.selectedPlatform,
			message: values.message,
			url: values.url,
			id: values.id
		}
		if (_.isEmpty(obj.id)) delete obj.id;
		this.setState({ loading: true });
		this.props.setUpgradeMessage(obj, (status) => {
			setSubmitting(false)
			this.setState({loading: false})
		});
	}
  }
  render() {
	const { classes } = this.props;
	return (
	  <GridContainer>
		<GridItem xs={12} sm={12} md={12}>
		  <Card>
			<CardHeader color="primary">
			  <h4 className={classes.cardTitleWhite}>Upgrade Package Message</h4>
			</CardHeader>
			<CardBody>
			{this.state.loading ?
				<Grid container item justify="center">
					<Spinner size={50} spinnerColor="#0093cb" spinnerWidth={5} visible={true} />
				</Grid> :
				<>
					<FormControl fullWidth margin="dense">
						<InputLabel htmlFor="outlined-source-simple">Select Platform</InputLabel>
						<Select
							name="platform"
							value={this.state.selectedPlatform}
							onChange={(e) => this.setState({selectedPlatform: e.target.value})}
							onBlur={() => this.setState({platformBlur: true})}
							helperText={!this.state.selectedPlatform && this.state.platformBlur && "Required"}
							error={!this.state.selectedPlatform && this.state.platformBlur}
							inputProps={{
								name: "platform",
								id: "outlined-source-simple"
							}}
						>
							<MenuItem value="">
								<em>None</em>
							</MenuItem>
							<MenuItem value="android">Android</MenuItem>
							<MenuItem value="ios">iOS</MenuItem>
						</Select>
						{!this.state.selectedPlatform && this.state.platformBlur &&
							<FormHelperText error={true}>Required</FormHelperText>
						}
					</FormControl>
					<Formik
						enableReinitialize={true}
						initialValues={{
							url: (_.has(this.state.upgradeMessage, this.state.selectedPlatform)) ? this.state.upgradeMessage[this.state.selectedPlatform].url : "",
							message: (_.has(this.state.upgradeMessage, this.state.selectedPlatform)) ? this.state.upgradeMessage[this.state.selectedPlatform].message : "",
							id: (_.has(this.state.upgradeMessage, this.state.selectedPlatform)) ? this.state.upgradeMessage[this.state.selectedPlatform].id : ""
						}}
						validate={values => {
							let errors = {};
							if (!values.url) errors.url = "Required";
							if (!values.message) errors.message = "Required";
							return errors;
						}}
						onSubmit={this.setUpgradeMessage}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							isSubmitting
						}) => (
							<Form>
								<TextField
									label="Url"
									name="url"
									placeholder="Package upgrade link"
									value={values.url}
									onChange={handleChange}
									onBlur={handleBlur}
									margin="normal"
									variant="outlined"
									fullWidth
									helperText={errors.url && touched.url && errors.url}
									error={errors.url && touched.url}
								/>
								<TextField
									label="Message"
									name="message"
									placeholder="Enter Upgrade Package Message"
									value={values.message}
									onChange={handleChange}
									onBlur={handleBlur}
									style={{ width: "100%" }}
									margin="normal"
									variant="outlined"
									rows={6}
									multiline
									helperText={
										errors.message && touched.message && errors.message
									}
									error={errors.message && touched.message}
								/>
								<Grid container justify="flex-end">
									<Button
										type="submit"
										color="primary"
										size="lg"
										onClick={handleSubmit}
										disabled={isSubmitting}
									>
										Submit
									</Button>
								</Grid>
							</Form>
						)}
					</Formik>
			  	</>
			}
			</CardBody>
		  </Card>
		</GridItem>
	  </GridContainer>
	);
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(UpgradeMessage));