import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {
    FormControl, InputLabel, Input, InputAdornment, IconButton
} from "@material-ui/core";
import {connect} from "react-redux";
import Spinner from "react-spinner-material";
import Search from "@material-ui/icons/Search";
import _ from "lodash";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "components/Table/Table.jsx";
import Card2 from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "./../../components/CustomButtons/Button";
import {mapStateToProps, mapDispatchToProps} from "./functions";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "400",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    emptyListMsg: {
        paddingTop: 20,
        paddingBottom: 20,
        margin: 0,
        color: "#0093cb"
    }
};

class Courses extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            syncButtonDisabled: false,
            page: 0,
            filterBy: "title",
            searchText: "",
            loading: true,
            syncingCourses: [],
            throttleFunc: _.throttle(this.getUserCall, 1000, { 'trailing': true })
        }
    }
    componentDidMount(){
        const { page, searchText, filterBy } = this.state;
        const { rowsPerPage } = this.props;
        this.props.getCourses(rowsPerPage, page * rowsPerPage, searchText, filterBy, (res) => {
            this.setState({ loading: false })
        });
    }
    componentDidUpdate(prevProps, prevState){
        const { page, searchText, filterBy } = this.state;
        const { rowsPerPage } = this.props;
        if (prevState.page !== page || prevProps.rowsPerPage !== rowsPerPage) {
            this.setState({loading: true});
            this.props.getCourses(rowsPerPage, page * rowsPerPage, searchText, filterBy, (res) => {
                this.setState({ loading: false })
            });
        }
        else if (prevState.searchText !== searchText) {
            // this.setState({ loading: true });
            // this.props.getCourses(rowsPerPage, page * rowsPerPage, searchText, filterBy, (res) => {
            //     this.setState({ loading: false })
            // });
        }
    }
    searchClick = () => {
        const { page, searchText, filterBy } = this.state;
        const { rowsPerPage } = this.props;
        this.setState({ loading: true });
        this.props.getCourses(rowsPerPage, page * rowsPerPage, searchText, filterBy, (res) => {
            this.setState({ loading: false })
        });
    }
    getUserCall = () => {
        const { page, searchText, filterBy } = this.state;
        const { rowsPerPage } = this.props;
        this.setState({ loading: true });
        this.props.getCourses(rowsPerPage, page * rowsPerPage, searchText, filterBy, (res) => {
            this.setState({ loading: false })
        });
    }
    handleChangePage = (event, page) => {
        this.setState({ page });
    }
    handleChangeRowsPerPage = event => {
        this.setState({ page: 0 });
        this.props.setRowsPerPage(event.target.value);
    }
    setCourseTitle = event => {
        this.setState({courseTitle: event.target.value})
    }
    setCourseUrl = event => {
        this.setState({courseUrl: event.target.value })
    }
    enableSyncButton = () => {
        this.setState({syncButtonDisabled: false});
    }
    syncAllCourses = () => {
        this.setState(state => ({syncButtonDisabled: true}), () => this.props.syncAllCourses(this.enableSyncButton));
    }
    syncCourseCall = (courseId) => {
        this.props.syncCourse(courseId, (id) => {
            this.setState(state => {
                let syncingCourses = [...state.syncingCourses];
                _.pull(syncingCourses, id);
                return {syncingCourses}
            })
        })
    }
    syncCourse = (courseId) => {
        this.setState({
            syncingCourses: _.concat(this.state.syncingCourses, courseId)
        }, () => this.syncCourseCall(courseId))
    }
    searchTextChange = event => {
        this.setState({ searchText: event.target.value })
    }
    render(){
        const { classes, courses, rowsPerPage, totalCourses } = this.props;
        const { syncButtonDisabled, page, loading, searchText, syncingCourses } = this.state;
        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card2>
                        <CardHeader color="primary">
                            <h4 className={classes.cardTitleWhite}>Courses</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer justify="space-between">
                                <GridItem xs={12} sm={6} md={3}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="search">Search</InputLabel>
                                        <Input
                                            id="search"
                                            placeholder="Search by Course"
                                            value={searchText}
                                            onChange={this.searchTextChange}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton color="primary" onClick={this.searchClick}>
                                                        <Search />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={3}>
                                    <Button fullWidth color="primary" onClick={this.syncAllCourses} disabled={syncButtonDisabled}>Sync All Courses</Button>
                                </GridItem>
                            </GridContainer>
                            {loading
                                ?
                                <GridContainer justify="center">
                                    <Spinner size={50} spinnerColor="#0093cb" spinnerWidth={5} visible={true} />
                                </GridContainer>
                                :
                                !_.isEmpty(courses)
                                    ?
                                    <Table
                                        tableHeaderColor="primary"
                                        tableHead={tableHead}
                                        tableData={courses}
                                        remove={() => { }}
                                        update={this.syncCourse}
                                        page={page}
                                        rowsPerPage={rowsPerPage}
                                        syncingCourses={syncingCourses}
                                        handleChangePage={this.handleChangePage}
                                        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                                        totalNumber={totalCourses}
                                    />
                                    :
                                    <GridContainer justify="center">
                                        <h4 className={classes.emptyListMsg}>No Course Found</h4>
                                    </GridContainer>
                            }
                        </CardBody>
                    </Card2>
                </GridItem>
            </GridContainer>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Courses));
const tableHead = [
    { id: "id", label: "Sr#", sortable: false },
    { id: "courseServer", label: "Server", sortable: false },
    { id: "title", label: "Title", sortable: false },
    { id: "update", label: "Action", sortable: false },
];