import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {
    Table, TableHead, TableRow, TableBody, TableCell, TableFooter, TablePagination, IconButton, Tooltip,
    TableSortLabel
} from "@material-ui/core";
import moment from "moment";
// import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
// import LastPageIcon from '@material-ui/icons/LastPage';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Sync from "@material-ui/icons/Sync";
import _ from "lodash";
// core components
import tableStyle from "assets/jss/material-dashboard-react/components/tableStyle.jsx";
import "./table.css";

class CustomTable extends React.Component{
    state = {
        order: 'asc',
        orderBy: 'sr',
        page: 0,
        rowsPerPage: 10,
    }
    handleChangePage = (event, page) => {
        this.setState({ page });
    }
    handleChangeRowsPerPage = event => {
        this.setState({ page: 0, rowsPerPage: event.target.value });
    }
    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';
        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }
        this.setState({ order, orderBy });
    }
    editTextChange = event => {
        this.props.editCountryTextChange(event.target.value)
    }
    onRowClick = id => {
        if(_.isFunction(this.props.onRowClick)) return this.props.onRowClick(id)
    }
    renderTableCell = (id, key, type, classes, page, rowsPerPage, index, row) => {
        if(id === "id"){
            return (
                <TableCell className={classes.tableCell} key={key}>{(index + 1) - (page * rowsPerPage)}</TableCell>
            )
        }
        else if (id === "edit/delete") {
            return (
                <TableCell className={classes.tableCell} key={key}>
                    {(type === "country" || type === "video")
                        &&
                        <Tooltip
                            id="tooltip-top"
                            title="Edit"
                            placement="top"
                        >
                            <IconButton onClick={() => this.props.editRow(row.id)} aria-label="Edit" className={classes.iconButton}>
                                <EditIcon className={classes.icon + " " + classes.edit} />
                            </IconButton>
                        </Tooltip>
                    }
                    <Tooltip
                        id="tooltip-top"
                        title="Delete"
                        placement="top"
                    >
                        <IconButton onClick={() => this.props.remove(row.id)} aria-label="Delete" className={classes.iconButton}>
                            <DeleteIcon className={classes.icon + " " + classes.delete} />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            )
        }
        else if (id === "update") {
            const syncing = _.includes(this.props.syncingCourses, row.id);
            return (
                <TableCell className={classes.tableCell} key={key}>
                    <Tooltip
                        id="tooltip-top"
                        title="Update"
                        placement="top"
                    >
                    {syncing 
                        ?
                        <IconButton disabled={true} onClick={() => this.props.update(row.id)} aria-label="Update" className={classes.iconButton + " rotate"}>
                            <Sync className={classes.icon + " " + classes.update} />
                        </IconButton>
                        :
                        <IconButton onClick={() => this.props.update(row.id)} aria-label="Update" className={classes.iconButton}>
                            <Sync className={classes.icon + " " + classes.update} />
                        </IconButton>
                    }
                    </Tooltip>
                </TableCell>
            )
        }
        else if (id === "lastSeenAt") {
            return(
                <TableCell className={classes.tableCell} key={key}>
                    {moment(row[id]).format("DD MMM YYYY, hh:mm a")}
                </TableCell>
            )
        }
        else {
            var additionalStyle = {};
            if (id === "accountType") {
                additionalStyle['text-transform'] = 'capitalize';
            }
            return (
                <TableCell className={classes.tableCell} style={additionalStyle} key={key}>
                    {_.isObject(row[id]) 
                        ? row[id].name 
                            ? row[id].name 
                            : row[id].answer && row[id].answer
                        : row[id]}
                </TableCell>
            )
        }
    }
    render(){
        const { classes, tableHead, tableData, tableHeaderColor, type, rowsPerPage, page, totalNumber } = this.props;
        const { order, orderBy } = this.state;
        // const emptyRows = rowsPerPage - Math.min(rowsPerPage, tableData.length - rowsPerPage);
        // const emptyRows = rowsPerPage - tableData.length;
        return (
            <div className={classes.tableResponsive}>
                <Table className={classes.table}>
                    <EnhancedTableHeadWrapped
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={this.handleRequestSort}
                        headRow={tableHead}
                        tableHeaderColor={tableHeaderColor}
                    />
                    <TableBody>
                        {stableSort(tableData, order, orderBy).map((row, index) => {
                            let total = totalNumber-1;
                            index = total - index;
                            return (
                                <TableRow key={row.id} onClick={() => this.onRowClick(row.id)} hover={true} style={{cursor: "pointer"}}>
                                    {_.map(tableHead, (heading, key) => this.renderTableCell(heading.id, key, type, classes, page, rowsPerPage, index, row))}
                                </TableRow>
                            );
                        })}
                        {/* {_.map(_.times(emptyRows), (i) => <TableRow key={i}></TableRow>)} */}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[100,500,2500,5000,10000]}
                                // colSpan={3}
                                count={totalNumber}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={this.props.handleChangePage}
                                onChangeRowsPerPage={this.props.handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActionsWrapped}
                                labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count}`}
                                nextIconButtonProps={{dataLength: totalNumber}}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </div>
        );
    }
}

CustomTable.defaultProps = {
    tableHeaderColor: "gray"
};

CustomTable.propTypes = {
    classes: PropTypes.object.isRequired,
    tableHeaderColor: PropTypes.oneOf([
        "warning",
        "primary",
        "danger",
        "success",
        "info",
        "rose",
        "gray"
    ]),
};

export default withStyles(tableStyle)(CustomTable);

const actionsStyles = theme => ({
    root: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        // marginLeft: theme.spacing.unit * 2.5,
    },
});

class TablePaginationActions extends React.Component {
    // handleFirstPageButtonClick = event => {
    //     this.props.onChangePage(event, 0);
    // };

    handleBackButtonClick = event => {
        this.props.onChangePage(event, this.props.page - 1);
    };

    handleNextButtonClick = event => {
        this.props.onChangePage(event, this.props.page + 1);
    };

    // handleLastPageButtonClick = event => {
    //     this.props.onChangePage(
    //         event,
    //         Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
    //     );
    // };

    render() {
        const { classes, page, theme, rowsPerPage, nextIconButtonProps } = this.props;
        return (
            <div className={classes.root}>
                {/* <IconButton
                    onClick={this.handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="First Page"
                >
                    {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton> */}
                <IconButton
                    onClick={this.handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="Previous Page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>
                <IconButton
                    onClick={this.handleNextButtonClick}
                    disabled={nextIconButtonProps.dataLength < rowsPerPage}
                    // disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="Next Page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>
                {/* <IconButton
                    onClick={this.handleLastPageButtonClick}
                    // disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="Last Page"
                >
                    {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton> */}
            </div>
        );
    }
}

TablePaginationActions.propTypes = {
    classes: PropTypes.object.isRequired,
    // count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    theme: PropTypes.object.isRequired,
};

const TablePaginationActionsWrapped = withStyles(actionsStyles, { withTheme: true })(
    TablePaginationActions,
);

class EnhancedTableHead extends React.Component {
    createSortHandler = (property) => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const { classes, order, orderBy, headRow, tableHeaderColor } = this.props;

        return (
            <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
                <TableRow>
                    {_.map(headRow, row => (
                        <TableCell
                            key={row.id}
                            className={classes.tableCell + " " + classes.tableHeadCell}
                            sortDirection={orderBy === row.id ? order : false}
                        >
                            <Tooltip
                                title="Sort"
                                enterDelay={300}
                                disableFocusListener={!row.sortable}
                                disableHoverListener={!row.sortable}
                                disableTouchListener={!row.sortable}
                            >
                                <TableSortLabel
                                    active={(orderBy === row.id) && row.sortable}
                                    direction={order}
                                    onClick={this.createSortHandler(row.id)}
                                    disabled={!row.sortable}
                                >
                                    {row.label}
                                </TableSortLabel>
                            </Tooltip>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    headRow: PropTypes.array.isRequired
};

const EnhancedTableHeadWrapped = withStyles(tableStyle, { withTheme: true })(
    EnhancedTableHead,
);

function stableSort(array, order, orderBy) {
    const sorted = _.orderBy(array, [obj => _.isString(obj[orderBy]) ? obj[orderBy].toLowerCase() : obj[orderBy]], order)
    return sorted;
}