import _ from "lodash";
import {apiWithCallbacks} from "./../../helpers/apiManager";
import {videosList, addVideo, setRowsPerPage} from "./../../redux/actions/appData";
import {alertPopupAction} from "./../../redux/actions/generalConfig";

export const mapStateToProps = (state) => {
    return {
        videos: state.appData.videos,
        rowsPerPage: state.appData.rowsPerPage
    }
}

export const mapDispatchToProps = (dispatch) => {
    return {
        getVideos: (limit, skip, callback) => dispatch(getAllVideos(limit, skip, callback)),
        addVideo: (data, callBack) => dispatch(addNewVideo(data, callBack)),
        updateVideo: (data, videoId, callBack) => dispatch(updateVideo(data, videoId, callBack)),
        deleteVideo: (videoId) => dispatch(deleteVideo(videoId)),
        setRowsPerPage: rows => dispatch(setRowsPerPage(rows))
    }
}

/*------------- Get All Videos -------------*/
const getAllVideos = (limit, skip, callback) => {
    return (dispatch, getState) => {
        let params = {
            method: "GET",
            path: "videos",
            header: {
                access_token: getState().user.user.authToken
            },
            params: {
                limit, skip
            },
            callback
        }
        dispatch(apiWithCallbacks(params, onSuccessGetAllVideos, onFailureGetAllVideos));
    }
}
const onSuccessGetAllVideos = (response, params) => {
    return (dispatch) => {
        console.log("onSuccessGetAllVideos", response, params);
        dispatch(videosList(response.data));
        params.callback();
    }
}
const onFailureGetAllVideos = (response, params) => {
    return (dispatch) => {
        console.log("onFailureGetAllVideos", response, params);
        params.callback();
    }
}

/*------------- Add New Video --------------------*/
const addNewVideo = (data, callBack) => {
    return (dispatch, getState) => {
        dispatch(alertPopupAction(false, "", getState().generalConfig.alertPopup.color));
        let params = {
            method: "POST",
            path: "videos",
            data,
            header: {
                access_token: getState().user.user.authToken
            },
            callBack
        }
        console.log("addNewVideo",params);
        dispatch(apiWithCallbacks(params ,onSuccessAddVideo, onFailureAddVideo));
    }
}
const onSuccessAddVideo = (response, params) => {
    return (dispatch) => {
        console.log("onSuccessAddVideo", response, params);
        dispatch(addVideo(response.data));
        params.callBack(true);
        dispatch(alertPopupAction(true, "New Video Added Successfully", "success"));
    }
}
const onFailureAddVideo = (response, params) => {
    return (dispatch) => {
        console.log("onFailureAddVideo", response, params);
        params.callBack(false);
        dispatch(alertPopupAction(true, "Video Not Added", "danger"))
    }
}

/*------------- Update Video -----------------*/
const updateVideo = (data, videoId ,callBack) => {
    return (dispatch, getState) => {
        dispatch(alertPopupAction(false, "", getState().generalConfig.alertPopup.color));
        let params = {
            method: "PATCH",
            path: `videos/${videoId}`,
            data,
            header: {
                access_token: getState().user.user.authToken
            },
            callBack
        }
        dispatch(apiWithCallbacks(params, onSuccessUpdateVideo, onFailureUpdateVideo));
    }
}
const onSuccessUpdateVideo = (response, params) => {
    return (dispatch, getState) => {
        console.log("onSuccessUpdateVideo", response, params);
        let videoIndex = _.findIndex(getState().appData.videos, video => video.id === response.data.id);
        let videos = _.assign([...getState().appData.videos], {[videoIndex]: response.data});
        dispatch(videosList(videos));
        params.callBack(true);
        dispatch(alertPopupAction(true, "Video Updated Successfully", "success"));
    }
}
const onFailureUpdateVideo = (response, params) => {
    return (dispatch) => {
        console.log("onFailureUpdateVideo", response, params);
        params.callBack(false);
        dispatch(alertPopupAction(true, "Video Not Updated", "danger"));
    }
}

/*---------------- Delete Video ---------------*/
const deleteVideo = (videoId) => {
    return (dispatch, getState) => {
        dispatch(alertPopupAction(false, "", getState().generalConfig.alertPopup.color));
        let params = {
            method: "DELETE",
            path: `videos/${videoId}`,
            header: {
                access_token: getState().user.user.authToken
            }
        }
        dispatch(apiWithCallbacks(params, onSuccessDeleteVideo, onFailureDeleteVideo));
    }
}
const onSuccessDeleteVideo = (response, params) => {
    return (dispatch, getState) => {
        console.log("onSuccessDeleteVideo", response, params);
        let videos = [...getState().appData.videos];
        _.remove(videos, video => video.id === response.data.id);
        dispatch(videosList(videos));
        dispatch(alertPopupAction(true, "Video Deleted Successfully", "success"));
    }
}
const onFailureDeleteVideo = (response, params) => {
    return (dispatch) => {
        console.log("onFailureDeleteVideo", response, params);
        dispatch(alertPopupAction(true, "Video Not Deleted", "danger"));
    }
}