const initialState = {
    users: [],
    totalUserRecords: 0,
    totalCountryRecords: 0,
    totalCourseRecords: 0,
    totalQuizRecords: 0,
    totalFeedbackRecords: 0,
    countries: [],
    videos: [],
    feedbackQuestions: [],
    feedbacks: [],
    courses: [],
    quizzes: [],
    rowsPerPage: 2500,
	welcomeMessage: "",
	upgradeMessage: [],
    termsAndConditions: ""
}

export default function(state = initialState, action){
    switch(action.type){
        case "USERS_LIST":
            return {...state, users: action.data}
        case "TOTAL_USER_RECORDS":
            return {...state, totalUserRecords: action.data}
        case "TOTAL_COUNTRY_RECORDS":
            return {...state, totalCountryRecords: action.data}
        case "TOTAL_FEEDBACK_RECORDS":
            return {...state, totalFeedbackRecords: action.data}
        case "TOTAL_COURSE_RECORDS":
            return {...state, totalCourseRecords: action.data}
        case "TOTAL_QUIZ_RECORDS":
            return {...state, totalQuizRecords: action.data}
        case "COUNTRIES_LIST":
            return {...state, countries: action.data}
        case "ADD_COUNTRY":
            return {...state, countries: [...state.countries, action.data]}
        case "VIDEOS_LIST":
            return {...state, videos: action.data}
        case "ADD_VIDEO":
            return {...state, videos: [...state.videos, action.data]}
        case "FEEDBACK_QUESTIONS":
            return {...state, feedbackQuestions: action.data}
        case "FEEDBACKS_LIST":
            return {...state, feedbacks: action.data}
        case "COURSES_LIST":
            return {...state, courses: action.data}
        case "QUIZZES_LIST":
            return {...state, quizzes: action.data}
        case "ROWS_PER_PAGE":
            return {...state, rowsPerPage: action.data}
        case "WELCOME_MESSAGE":
			return {...state, welcomeMessage: action.data}
		case "UPGRADE_MESSAGE":
			return {...state, upgradeMessage: action.data}
        case "TERMS_AND_CONDITIONS":
            return {...state, termsAndConditions: action.data}
        case "LOGOUT": 
            return state = initialState
        default:
            return state
    }
}