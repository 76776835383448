import _ from "lodash";
import {apiWithCallbacks} from "./../../helpers/apiManager";
import {quizzesList, setRowsPerPage, totalQuizRecords} from "./../../redux/actions/appData";
import {alertPopupAction} from "./../../redux/actions/generalConfig";

export const mapStateToProps = (state) => {
    return {
        quizzes: state.appData.quizzes,
        rowsPerPage: state.appData.rowsPerPage,
        totalQuizzes: state.appData.totalQuizRecords
    }
}

export const mapDispatchToProps = (dispatch) => {
    return {
        getQuizzes: (limit, skip, searchText, searchBy, callback) => dispatch(getQuizzes(limit, skip, searchText, searchBy, callback)),
        syncAllQuizzes: (callback) => dispatch(syncAllQuizzes(callback)),
        syncQuiz: (quizId, callback) => dispatch(syncQuiz(quizId, callback)),
        setRowsPerPage: rows => dispatch(setRowsPerPage(rows))
    }
}

/*---------- Get All Courses ------------*/
const getQuizzes = (limit, skip, searchText, searchBy, callback) => {
    return (dispatch, getState) => {
        let params = {};
        if(!_.isNil(limit)) params.limit = limit;
        if(!_.isNil(skip)) params.skip = skip;
        if (!_.isNil(searchBy) && !_.isNil(searchText)){
            // const where = {[searchBy]: {contains: searchText}};
            params.where = searchText;
        }
        let data = {
            method: "GET",
            path: "quizzes",
            header: {
                access_token: getState().user.user.authToken
            },
            params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessgetQuizzes, onFailuregetQuizzes));
    }
}
const onSuccessgetQuizzes = (response, params) => {
    return (dispatch) => {
        console.log("onSuccessgetQuizzes", response, params);
        let quizzes = _.map(response.data.quizzes, course => _.pick(course, ["id", "title"]));
        dispatch(quizzesList(quizzes));
        dispatch(totalQuizRecords(response.data.totalQuizzes));
        params.callback();
    }
}
const onFailuregetQuizzes = (response, params) => {
    return (dispatch) => {
        console.log("onFailuregetQuizzes", response, params);
        params.callback();
    }
}

/*----------- Sync All Courses --------------*/
const syncAllQuizzes = (callback) => {
    return (dispatch, getState) => {
        dispatch(alertPopupAction(false, "", getState().generalConfig.alertPopup.color));
        let params = {
            method: "GET",
            path: "quizzes/sync",
            header: {
                access_token: getState().user.user.authToken
            },
            callback
        }
        dispatch(apiWithCallbacks(params, onSuccesssyncAllQuizzes, onFailuresyncAllQuizzes));
    }
}
const onSuccesssyncAllQuizzes = (response, params) => {
    return (dispatch) => {
        console.log("onSuccesssyncAllQuizzes", response, params);
        params.callback();
        dispatch(alertPopupAction(true, "Quiz data will be synced in background", "success"));
    }
}
const onFailuresyncAllQuizzes = (response, params) => {
    return (dispatch) => {
        console.log("onFailuresyncAllQuizzes", response, params);
        params.callback();
        dispatch(alertPopupAction(true, "All Quizzes Sync Failed", "danger"));
    }
}

/*------------ Sync Single Course ------------*/
const syncQuiz = (quizId, callback) => {
    return (dispatch, getState) => {
        dispatch(alertPopupAction(false, "", getState().generalConfig.alertPopup.color));
        let params = {
            method: "GET",
            path: `quizzes/${quizId}/sync`,
            header: {
                access_token: getState().user.user.authToken
            },
            callback, quizId
        }
        dispatch(apiWithCallbacks(params, onSuccesssyncQuiz, onFailuresyncQuiz));
    }
}
const onSuccesssyncQuiz = (response, params) => {
    return (dispatch) => {
        console.log("onSuccesssyncQuiz", response, params);
        dispatch(alertPopupAction(true, "Quiz data will be synced in background", "success"));
        params.callback(params.quizId);
    }
}
const onFailuresyncQuiz = (response, params) => {
    return (dispatch) => {
        console.log("onFailuresyncQuiz", response, params);
        dispatch(alertPopupAction(true, "Quizzes Sync Failed", "danger"));
        params.callback(params.quizId);
    }
}