import _ from "lodash";

export function getFormData(object) {
    var formData = new FormData();
    Object.keys(object).forEach(key => formData.append(key, object[key]));
    return formData;
}

const patterns = {
    passwordPattern: /(.){6,12}/,
    emailPattern: /(.+)@(.+){2,}\.(.+){2,}/,
}

export function validateEmail(email) {
    if (_.isEmpty(email)) return "Required";
    if (patterns.emailPattern.test(email) === false) return "Invalid Email Address";
    return
}

export function validatePassword(password) {
    if (_.isEmpty(password)) return "Required";
    // if (patterns.passwordPattern.test(password) == false) return "Password"
    return
}