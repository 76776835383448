import {apiWithCallbacks} from "./../../helpers/apiManager";
import {alertPopupAction} from "./../../redux/actions/generalConfig";

export const mapStateToProps = (state) => {
    return {
        user: state.user.user
    }
}
export const mapDispatchToProps = (dispatch) => {
    return {
        changePassword: (data, callback) => dispatch(changePassword(data, callback))
    }
}

/*------------ Change Password Api Call ----------*/
const changePassword = (data, callback) => {
    return (dispatch, getState) => {
        dispatch(alertPopupAction(false, "", getState().generalConfig.alertPopup.color));
        let params = {
            method: "POST",
            path: "account/reset-password",
            data,
            header: {
                access_token: getState().user.user.authToken
            },
            callback
        }
        dispatch(apiWithCallbacks(params, onSuccessChangePassword, onFailureChangePassword))
    }
}
const onSuccessChangePassword = (response, params) => {
    return (dispatch) => {
        console.log("onSuccessChangePassword", response);
        params.callback(true);
        if(response.data) dispatch(alertPopupAction(true, response.data, "success"));
        else dispatch(alertPopupAction(true, "If your account was registered with this email, you would receive email to reset you password", "success"));
    }
}
const onFailureChangePassword = (response, params) => {
    return (dispatch) => {
        console.log("onFailureChangePAssword", response);
        params.callback(false);
        dispatch(alertPopupAction(true, "Change Password Failed", "danger"));
    }
}