import _ from "lodash";
import { apiWithCallbacks } from "../../helpers/apiManager";
import { alertPopupAction } from "../../redux/actions/generalConfig";
import { termsAndConditions } from "../../redux/actions/appData";

export const mapStateToProps = state => {
  return {
    termsAndConditions: state.appData.termsAndConditions
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    getTermsAndConditions: cb => dispatch(getTermsAndConditions(cb)),
    setTermsAndConditions: (data, cb) => dispatch(setTermsAndConditions(data, cb))
  };
};

/*--------------- ApiCall ------------*/
const getTermsAndConditions = (callback) => {
  return (dispatch, getState) => {
    dispatch(
      alertPopupAction(false, "", getState().generalConfig.alertPopup.color)
    );
    let params = {
        method: 'GET',
        path: 'termsAndConditions',
        header: {
            access_token: getState().user.user.authToken
        },
        callback
    };
    dispatch(apiWithCallbacks(params, onSuccessTermsAndConditions, onFailureTermsAndConditions));
  };
};

const setTermsAndConditions = (data, callback) => {
    return (dispatch, getState) => {
        dispatch(
            alertPopupAction(false, "", getState().generalConfig.alertPopup.color)
        );
        let params = {
            method: 'POST',
            path: 'termsAndConditions',
            data,
            header: {
                access_token: getState().user.user.authToken
            },
            callback
        }
        dispatch(apiWithCallbacks(params, onSuccessTermsAndConditions, onFailureTermsAndConditions));
    }
}

const onSuccessTermsAndConditions = (response, params) => {
    return dispatch => {
        if(response.data && _.isArray(response.data) && response.data.length > 0) {
            dispatch(termsAndConditions(response.data[0]));
            if(_.has(params, "callback") && _.isFunction(params.callback)) {
                params.callback(true);
            }

        } else if (response.data && _.isObject(response.data)) {
            dispatch(termsAndConditions(response.data));
            if(_.has(params, "callback") && _.isFunction(params.callback)) {
                params.callback(true);
            }
        }

        if(params.method === "POST") {
            dispatch(alertPopupAction(true, "Terms & Conditions updated", "success"));
        }
    }
}

const onFailureTermsAndConditions = (response, params) => {
    return dispatch => {
        dispatch(alertPopupAction(true, "Error in setting Terms & Conditions", "danger"));

        if(_.has(params, "callback") && _.isFunction(params.callback)) params.callback(false);
    };
}