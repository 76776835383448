const initialState = {
    user: {}
}

export default function(state = initialState, action){
    switch(action.type){
        case "LOGIN_SUCCESS":
            return {...state, user: action.data}
        case "LOGOUT":
            return state = initialState
        default:
            return state
    }
}