export const usersList = (users) => {
    return {
        type: "USERS_LIST",
        data: users
    }
}
export const totalUserRecords = (totalUsers) => {
    return {
        type: "TOTAL_USER_RECORDS",
        data: totalUsers
    }
}
export const totalCountryRecords = (totalCountries) => {
    return {
        type: "TOTAL_COUNTRY_RECORDS",
        data: totalCountries
    }
}
export const totalFeedbackRecords = (totalFeedbacks) => {
    return {
        type: "TOTAL_FEEDBACK_RECORDS",
        data: totalFeedbacks
    }
}
export const totalCourseRecords = (totalCourses) => {
    return {
        type: "TOTAL_COURSE_RECORDS",
        data: totalCourses
    }
}
export const totalQuizRecords = (totalQuizzes) => {
    return {
        type: "TOTAL_QUIZ_RECORDS",
        data: totalQuizzes
    }
}
export const countriesList = (countries) => {
    return {
        type: "COUNTRIES_LIST",
        data: countries
    }
}
export const addCountry = (country) => {
    return {
        type: "ADD_COUNTRY",
        data: country
    }
}
export const videosList = (videos) => {
    return {
        type: "VIDEOS_LIST",
        data: videos
    }
}
export const addVideo = (video) => {
    return {
        type: "ADD_VIDEO",
        data: video
    }
}
export const feedbackQuestions = (questions) => {
    return {
        type: "FEEDBACK_QUESTIONS",
        data: questions
    }
}
export const feedbacksList = (feedbacks) => {
    return {
        type: "FEEDBACKS_LIST",
        data: feedbacks
    }
}
export const coursesList = (courses) => {
    return {
        type: "COURSES_LIST",
        data: courses
    }
}
export const quizzesList = (quizzes) => {
    return {
        type: "QUIZZES_LIST",
        data: quizzes
    }
}
export const setRowsPerPage = (rowsPerPage) => {
    return {
        type: "ROWS_PER_PAGE",
        data: rowsPerPage
    }
}
export const welcomeMessage = (message) => {
    return {
        type: "WELCOME_MESSAGE",
        data: message
    }
}
export const upgradeMessage = (data) => {
	return {
		type: "UPGRADE_MESSAGE",
		data
	}
}
export const termsAndConditions = (data) => {
    return {
        type: "TERMS_AND_CONDITIONS",
        data
    }
}
