import _ from "lodash";
import {apiWithCallbacks} from "./../../helpers/apiManager";
import {countriesList, addCountry, setRowsPerPage, totalCountryRecords} from "./../../redux/actions/appData";
import {alertPopupAction} from "./../../redux/actions/generalConfig";

export const mapStateToProps = (state) => {
    return {
        countries: state.appData.countries,
        rowsPerPage: state.appData.rowsPerPage,
        totalCountries: state.appData.totalCountryRecords
    }
}

export const mapDispatchToProps = (dispatch) => {
    return {
        getAllCountries: (limit, skip, callback) => dispatch(getAllCountries(limit, skip, callback)),
        addCountry: (data, callBack) => dispatch(addNewCountry(data, callBack)),
        updateCountry: (data, countryId ,callBack) => dispatch(updateCountry(data, countryId, callBack)),
        deleteCountry: (countryId) => dispatch(deleteCountry(countryId)),
        setRowsPerPage: rows => dispatch(setRowsPerPage(rows))
    }
}

/*-------------- Get All Countries --------------*/
export const getAllCountries = (limit, skip, callback) => {
    return (dispatch, getState) => {
        let params = {
            method: "GET",
            path: "countries",
            header: {
                access_token: getState().user.user.authToken
            },
            params: {
                limit, skip
            },
            callback
        }
        dispatch(apiWithCallbacks(params, onSuccessGetAllCountries, onFailureGetAllCountries));
    }
}
const onSuccessGetAllCountries = (response, params) => {
    return (dispatch) => {
        console.log("onSuccessGetAllCountries", response, params);
        dispatch(countriesList(response.data.countries));
        dispatch(totalCountryRecords(response.data.totalCountries));
        if (!_.isNil(params.callback) && _.isFunction(params.callback)){
            params.callback();
        }
    }
}
const onFailureGetAllCountries = (response, params) => {
    return (dispatch) => {
        console.log("onFailureGetAllCountries", response, params);
        if (!_.isNil(params.callback) && _.isFunction(params.callback)) {
            params.callback();
        }
    }
}

/*--------------- Add New Country ----------------*/
const addNewCountry = (data, callBack) => {
    return (dispatch, getState) => {
        dispatch(alertPopupAction(false, "", getState().generalConfig.alertPopup.color));
        let params = {
            method: "POST",
            path: "countries",
            data: data,
            header: {
                access_token: getState().user.user.authToken
            },
            callBack
        }
        dispatch(apiWithCallbacks(params, onSuccessAddCountry, onFailureAddCountry));
    }
}
const onSuccessAddCountry = (response, params) => {
    return (dispatch) => {
        console.log("onSuccessAddCountry", response, params);
        dispatch(addCountry(response.data));
        params.callBack(true);
        dispatch(alertPopupAction(true, "New Country Added Successfully", "success"));
    }
}
const onFailureAddCountry = (response, params) => {
    return (dispatch) => {
        console.log("onFailureAddCountry", response, params);
        params.callBack(false);
        dispatch(alertPopupAction(true, "Country Addition Failed", "danger"));
    }
}

/*--------------- Update Country ----------------*/
const updateCountry = (data, countryId, callBack) => {
    return (dispatch, getState) => {
        dispatch(alertPopupAction(false, "", getState().generalConfig.alertPopup.color));
        let params = {
            method: "PATCH",
            path: `countries/${countryId}`,
            data,
            header: {
                access_token: getState().user.user.authToken
            },
            callBack
        }
        dispatch(apiWithCallbacks(params, onSuccessUpdateCountry, onFailureUpdatecountry));
    }
}
const onSuccessUpdateCountry = (response, params) => {
    return (dispatch, getState) => {
        console.log("onSuccessUpdateCountry",response)
        let countryIndex = _.findIndex(getState().appData.countries, country => country.id === response.data.id);
        let countries = _.assign([...getState().appData.countries], {[countryIndex]: response.data});
        dispatch(countriesList(countries));
        params.callBack(true);
        dispatch(alertPopupAction(true, "Country Updated Successfully", "success"));
    }
}
const onFailureUpdatecountry = (response, params) => {
    return (dispatch) => {
        console.log("onFailureUpdatecountry", response, params);
        params.callBack(false);
        dispatch(alertPopupAction(true, "Country Updating Failed", "danger"));
    }
}

/*---------------- Remove Country ----------------*/
const deleteCountry = (countryId) => {
    return (dispatch, getState) => {
        dispatch(alertPopupAction(false, "", getState().generalConfig.alertPopup.color));
        let params = {
            method: "DELETE",
            path: `countries/${countryId}`,
            header: {
                access_token: getState().user.user.authToken
            }
        }
        dispatch(apiWithCallbacks(params, onSuccessDeleteCountry, onFailureDeleteCountry));
    }
}
const onSuccessDeleteCountry = (response, params) => {
    return (dispatch, getState) => {
        console.log("onSuccessDeleteCountry", response, params);
        let countries = [...getState().appData.countries];
        _.remove(countries, country => country.id === response.data.id);
        dispatch(countriesList(countries));
        dispatch(alertPopupAction(true, "Country Deleted Successfully", "success"));
    }
}
const onFailureDeleteCountry = (response ,params) => {
    return (dispatch) => {
        console.log("onFailureDeleteCountry", response, params);
        dispatch(alertPopupAction(true, "Country Deletion Failed", "danger"));
    }
}