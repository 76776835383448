import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { TextField, Grid } from "@material-ui/core";
// core components
import { connect } from "react-redux";
import _ from "lodash";
import { Formik, Form } from "formik";
import Spinner from "react-spinner-material";
// custom components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "./../../components/CustomButtons/Button";
import { mapStateToProps, mapDispatchToProps } from "./functions";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "400",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  icon: {
    fontSize: 20
  },
  listItem: {
    margin: 0,
    padding: 10,
    alignItems: "flex-start"
  },
  textField: {
    marginTop: 0
  },
  emptyListMsg: {
    paddingTop: 20,
    paddingBottom: 20,
    margin: 0,
    color: "#0093cb"
  }
};

class WelcomeMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      title: "",
      message: ""
    };
  }
  componentDidMount() {
      this.setState({ loading: true });
      this.props.getWelcomeMessage(() => {
          this.setState({ loading: false });
        });
    }
    setWelcomeMessage = (values, {setSubmitting}) => {
    this.setState({ loading: true });
    this.props.setWelcomeMessage({title: values.title, message: values.message}, (status) => {
        setSubmitting(false)
        this.setState({loading: false})
    });
  }
  render() {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Welcome Message</h4>
            </CardHeader>
            <CardBody>
            {this.state.loading ?
                <Grid container item justify="center">
                    <Spinner size={50} spinnerColor="#0093cb" spinnerWidth={5} visible={true} />
                </Grid> : 
              <Formik
                initialValues={{ 
                    title: (_.has(this.props.welcomeMessage, "title")) ? this.props.welcomeMessage.title : "",
                    message: (_.has(this.props.welcomeMessage,"message")) ? this.props.welcomeMessage.message : "" 
                }}
                validate={values => {
                  let errors = {};
                  if (!values.title) errors.message = "Required";
                  if (!values.message) errors.message = "Required";
                  return errors;
                }}
                onSubmit={this.setWelcomeMessage}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting
                }) => (
                  <Form>
                    <TextField
                        label="Title"
                        name="title"
                        placeholder="Welcome Title"
                        value={values.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        helperText={errors.title && touched.title && errors.title}
                        error={errors.title && touched.title}
                    />
                    <TextField
                      label="Message"
                      name="message"
                      placeholder="Enter Welcome Message"
                      value={values.message}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{ width: "100%" }}
                      margin="normal"
                      variant="outlined"
                      rows={6}
                      multiline
                      helperText={
                        errors.message && touched.message && errors.message
                      }
                      error={errors.message && touched.message}
                    />
                    <Grid container justify="flex-end">
                      <Button
                        type="submit"
                        color="primary"
                        size="lg"
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Form>
                )}
              </Formik>
            }
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(WelcomeMessage));
