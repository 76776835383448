import React from "react";
  // @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {
  ListItem,
  TextField,
  ListItemText,
  Divider,
  Button as MUIButton,
  Grid,
  FormControl,
  InputLabel,
  Typography,
  List,
} from "@material-ui/core";
// core components
import { connect } from "react-redux";
import _, { merge, takeWhile } from "lodash";
import { Formik, Form } from "formik";
import Spinner from "react-spinner-material";
import Select, { createFilter } from "react-select";

// custom components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "./../../components/CustomButtons/Button";
import { mapStateToProps, mapDispatchToProps } from "./functions";



const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "400",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  icon: {
    fontSize: 20,
  },
  listItem: {
    margin: 0,
    padding: 10,
    alignItems: "flex-start",
  },
  textField: {
    marginTop: 0,
  },
  emptyListMsg: {
    paddingTop: 20,
    paddingBottom: 20,
    margin: 0,
    color: "#0093cb",
  },
  levellistItem: {
    paddingTop: "2px",
    paddingBottom: "2px",
  },
  active: {
    background: "#0093cb",
    "& span": {
      color: "#FFFFFF",
    },
  },
};


class Notification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      countloading: false,
      levels: [],
      selectLevel: "",
      levelobj: [],
      sessions: [],
      selectSessions: [],
      totalUsersCount: "",
      selectedUsersCount: "",
      selectAllLevel: [],
      getLevels: {},
      selectedEmails: [],
      allEmails: [],
    };
  }
  componentDidMount() {
    this.props.getLevels((res) => {
      this.getLevels(res);
    });
    this.props.allEmails((res) => {
      this.setState({allEmails: res});
    });
  }
  resetForm = (status, setSubmitting, resetForm) => {
    if (status) {
      this.setState({
        selectedEmails: [],
      });
    //  resetForm({ title: "", message: "" });
    }
    setSubmitting(false);
  };
  sendNotification = (values, { setSubmitting, resetForm }) => {
    let data = {
      title: values.title,
      message: values.message,
      selectLevel: this.state.selectLevel,
      selectSessions: this.state.selectSessions,
      selectAllLevel: this.state.selectAllLevel,
      selectedEmails: this.state.selectedEmails,
    };
    if(this.state.selectAllLevel == "" && this.state.selectLevel == "" && this.state.selectedEmails == "") {
      alert("Please Select Email or Level");
      return;
    }
    this.props.sendNotification(data, (status) =>
      this.resetForm(status, setSubmitting, resetForm)
    );
  };
  countUsers = () => {
    const { selectLevel, selectSessions, selectAllLevel } = this.state;
    this.setState({ countloading: true });
    this.props.countUsers(selectLevel, selectSessions, selectAllLevel, (res) => {
      this.setState({ totalUsersCount: res.users });
      this.setState({ selectedUsersCount: res.selectedusers });
      this.setState({ countloading: false });
    });
  };
  getLevels(res) {
    
    let levels = [];
    let c = 0;
    let lo = 0;
    let levObj = [];
   // let oldlevels = ["level1j", "level1d", "level1", "level2", "level3"];
    if (res != false) {
      res.level.forEach(i => {
        if(i != null) {
          levObj[lo] = i;
          lo++;
          Object.values(i).map((value, index) => {
            levels[c] = value["key"];
            c++;
          });
        }
      });
      //   Object.values(res.level).map((value, index) => {
      //     levObj[index] = value;
      //     Object.values(value).map((levelkey) => {
      //       levels[c] = levelkey.key;
      //       c++;
      //     });
      //   });
      this.setState({ loading: false });
      this.setState({ levelobj: levObj });
      this.setState({ levels: levels });
    //  this.setState({ levels: _.concat(levels, oldlevels) });
    }
  } 
  synclevels = () => {
    this.setState({ loading: true });
    this.props.saveLevels((res) => {
      this.setState({ loading: false });
      this.getLevels(res);
    });
  };
  levelSelect = (level) => {
    let session = [];
    this.setState({ selectLevel: level });
    // if (level == "level1") {
    //   session = ["dec", "feb", "may", "aug", "nov"];
    // }
    // if (level == "level2" || level == "level3" || level == "level1j") {
    //   session = ["june"];
    // }
    // if (level == "level1d") {
    //   session = ["dec"];
    // }
    Object.values(this.state.levelobj).map((a) => {
      a.map((s) => {
        if (level == s.key) {
          Object.values(s.sessions).map((v, w) => {
            session[w] = v.key;
          });
        }
      });
    });
    this.setState({ sessions: session });
    this.state.selectSessions = [];
    this.state.selectAllLevel = [];
  };
  selectSession = (value) => {
    if (this.state.selectSessions.indexOf(value) !== -1) {
      this.state.selectSessions = this.state.selectSessions.filter(
        (item) => item !== value
      );
    } else {
      this.state.selectSessions.push(value);
    }
    this.setState({ selectSessions: this.state.selectSessions });
  };
  selectAll = () => {
    this.state.sessions = [];
    this.setState({ selectAllLevel: this.state.levels });
  };
  changeEmails = selectedEmails => {
    this.setState(
      { selectedEmails },
      () => console.log(`Option selected:`, this.state.selectedEmails)
    );
  };

  render() {
    const { classes } = this.props;
    const {
      loading,
      countloading,
      levels,
      selectLevel,
      sessions,
      selectSessions,
      totalUsersCount,
      selectedUsersCount,
      selectAllLevel,
      selectedEmails,
      allEmails
    } = this.state;
    return <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Send Notification</h4>
            </CardHeader>
            <CardBody>
              <Button color="primary" size="sm" onClick={this.synclevels}>
                Sync Levels
              </Button>
              {loading ? <GridContainer justify="center">
                  <Spinner size={50} spinnerColor="#0093cb" spinnerWidth={5} visible={true} />
                </GridContainer> : <Formik initialValues={{ title: "", message: "", email: "" }} validate={(values) => {
                    let errors = {};
                    if (!values.title) errors.title = "Required";
                    if (!values.message) errors.message = "Required";
                    return errors;
                  }} onSubmit={this.sendNotification}>
                  {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => <Form>
                      <Card style={{ border: errors.selected ? "1px solid red" : null }}>
                        <CardHeader>
                          <Grid container alignItems="center" spacing={8}>
                            <Grid container item xs={12} sm={6} md={4}>
                              <InputLabel
                                style={{
                                  fontWeight: 400,
                                  color: "#111111",
                                }}
                              >
                                Level
                              </InputLabel>
                              <List style={{ width: "100%", border: "2px solid black", borderRadius: "5px", maxHeight: 90, overflowY: "auto" }}>
                                {levels.map((value) => (
                                  <ListItem
                                    key={value}
                                    className={[
                                      classes.levellistItem,
                                      selectLevel === value
                                        ? classes.active
                                        : "",
                                      selectAllLevel != ""
                                        ? classes.active
                                        : "",
                                    ]}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.levelSelect(value)}
                                  >
                                    <ListItemText
                                      style={{ padding: 0 }}
                                      primary={`${value}`}
                                      className={
                                        selectLevel == value
                                          ? classes.colorWhite
                                          : "ttttt"
                                      }
                                    />
                                  </ListItem>
                                ))}
                              </List>
                            </Grid>
                            <Grid container item xs={12} sm={6} md={3} justify="space-between">
                              <InputLabel
                                style={{
                                  fontWeight: 400,
                                  color: "#111111",
                                }}
                              >
                                Session
                              </InputLabel>
                              <List style={{ width: "100%", border: "2px solid black", borderRadius: "5px", height: 85, maxHeight: 85, overflowY: "auto" }}>
                                {sessions.map((value) => (
                                  <ListItem
                                    key={value}
                                    className={[
                                      classes.levellistItem,
                                      selectSessions.includes(value)
                                        ? classes.active
                                        : "notactive",
                                    ]}
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.selectSession(value)
                                    }
                                  >
                                    <ListItemText
                                      style={{ padding: 0 }}
                                      primary={`${value}`}
                                    />
                                  </ListItem>
                                ))}
                              </List>
                            </Grid>
                            <Grid container item xs={12} sm={6} md={3} alignItems="center" justify="center">
                              {countloading ? <GridContainer justify="center">
                                  <Spinner size={50} spinnerColor="#0093cb" spinnerWidth={5} visible={true} />
                                </GridContainer> : <Grid item sm={12} style={{ width: "100%" }}>
                                  <p style={{ textAlign: "center", marginBottom: "-10px" }}>
                                    <strong>Selected Users: </strong>
                                    <span>{selectedUsersCount}</span>
                                  </p>
                                  <p style={{ textAlign: "center", marginBottom: "0px" }}>
                                    <strong>Total Users: </strong> <span>
                                      {totalUsersCount}
                                    </span>
                                  </p>
                                  <Button color="primary" size="md" onClick={this.countUsers} style={{ display: "flex", margin: "auto" }}>
                                    Count
                                  </Button>
                                </Grid>}
                            </Grid>
                            <Grid container item xs={12} sm={6} md={2}>
                              <Button color="primary" size="sm" onClick={this.selectAll}>
                                Select All
                              </Button>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                              <Divider variant="fullWidth" />
                            </Grid>
                          </Grid>
                        </CardHeader>
                        <CardBody>
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <Select 
                                placeholder="Select Email" 
                                filterOption={createFilter( { ignoreAccents: false })} 
                                value={selectedEmails} 
                                options={Object.values(allEmails)} 
                                onChange={this.changeEmails} 
                                isMulti 
                                isSearchable
                              />
                            </FormControl>
                          </Grid>
                        </CardBody>
                        <CardFooter>
                          <Grid container item xs={12} sm={12} md={12}>
                            <Typography variant="subheading" color="error">
                              {errors.selected}
                            </Typography>
                          </Grid>
                        </CardFooter>
                      </Card>
                      <TextField label="Title" name="title" placeholder="Message Title" value={values.title} onChange={handleChange} onBlur={handleBlur} margin="normal" variant="outlined" fullWidth helperText={errors.title && touched.title && errors.title} error={errors.title && touched.title} />
                      <TextField label="Message" name="message" placeholder="Enter Notification Message" value={values.message} onChange={handleChange} onBlur={handleBlur} style={{ width: "100%" }} margin="normal" variant="outlined" rows={6} multiline helperText={errors.message && touched.message && errors.message} error={errors.message && touched.message} />
                      <Grid container justify="flex-end">
                        <Button type="submit" color="primary" size="lg" onClick={handleSubmit}>
                          Submit
                        </Button>
                      </Grid>
                    </Form>}
                </Formik>}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>;
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Notification));
