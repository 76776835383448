import React, { Component } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {
    Grid, Dialog, DialogTitle, Card as MUICard, CardContent, CardActions, TextField
} from "@material-ui/core";
import _ from 'lodash';
import { Formik, Form } from "formik";
import {connect} from "react-redux";
import Spinner from "react-spinner-material";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "components/Table/Table.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "./../../components/CustomButtons/Button";
import {mapStateToProps, mapDispatchToProps} from "./functions";
import {getFormData} from "./../../helpers/helpers";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "400",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    root: {
        width: "100%",
        margin: 0,
        padding: 0,
    },
    cardBody: {
        padding: "4px 8px"
    },
    iconButton: {
        width: "27px",
        height: "27px",
        padding: "0",
        margin: 0,
    },
    emptyListMsg: {
        paddingTop: 20,
        paddingBottom: 20,
        margin: 0,
        color: "#0093cb"
    }
};

class Country extends Component{
    constructor(props){
        super(props);
        this.state = {
            addCountryForm: false,
            editCountryForm: false,
            selectedCountry: undefined,
            page: 0,
            loading: true
        }
    }
    componentDidMount(){
        this.props.getAllCountries(this.props.rowsPerPage, this.state.page * this.props.rowsPerPage, (res) => {
            this.setState({ loading: false })
        });
    }
    componentDidUpdate(prevProps, prevState){
        if (prevState.page !== this.state.page || prevProps.rowsPerPage !== this.props.rowsPerPage) {
            this.setState({loading: true});
            this.props.getAllCountries(this.props.rowsPerPage, this.state.page * this.props.rowsPerPage, (res) => {
                this.setState({ loading: false })
            });
        }
    }
    handleChangePage = (event, page) => {
        this.setState({ page });
    }
    handleChangeRowsPerPage = event => {
        this.setState({ page: 0 });
        this.props.setRowsPerPage(event.target.value);
    }
    showAddCountryForm = () => {
        this.setState({ addCountryForm: true });
    }
    hideAddCountryForm = () => {
        this.setState({ addCountryForm: false });
    }
    showEditCountryForm = () => {
        this.setState({ editCountryForm: true });
    }
    hideEditCountryForm = () => {
        this.setState({ editCountryForm: false });
    }
    submitAddCountry = (values, { setSubmitting }) => {
        let { name, code2c, code3c } = values;
        let obj = {
            name: _.upperFirst(name),
            code2c: _.toUpper(code2c),
            code3c: _.toUpper(code3c)
        }
        let data = getFormData(obj);
        this.props.addCountry(data, (status) => { setSubmitting(false); status && this.hideAddCountryForm() });
    }
    submitUpdateCountry = (values, { setSubmitting }) => {
        let { name, code2c, code3c } = values;
        let obj = {
            name: _.upperFirst(name),
            code2c: _.toUpper(code2c),
            code3c: _.toUpper(code3c)
        }
        let data = getFormData(obj);
        this.props.updateCountry(data, this.state.selectedCountry.id, (status) => { setSubmitting(false); status && this.hideEditCountryForm() });
    }
    editCountry = (id) => {
        const country = _.find(this.props.countries, country => country.id === id);
        if(!_.isUndefined(country)) this.setState({selectedCountry: country, editCountryForm: true});
    }
    removeCountry = (id) => {
        this.props.deleteCountry(id);
    }
    render(){
        const { classes, countries, rowsPerPage, totalCountries } = this.props;
        const { addCountryForm, editCountryForm, page, loading } = this.state;
        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className={classes.cardTitleWhite}>Country</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={6} md={3}>
                                    <Button fullWidth color="primary" onClick={this.showAddCountryForm}>Add New Country</Button>
                                </GridItem>
                            </GridContainer>
                            {loading
                                ?
                                <GridContainer justify="center">
                                    <Spinner size={50} spinnerColor="#0093cb" spinnerWidth={5} visible={true} />
                                </GridContainer>
                                :
                                !_.isEmpty(countries)
                                    ?
                                    <Table
                                        tableHeaderColor="primary"
                                        tableHead={tableHead}
                                        tableData={countries}
                                        editRow={this.editCountry}
                                        remove={this.removeCountry}
                                        type="country"
                                        page={page}
                                        rowsPerPage={rowsPerPage}
                                        handleChangePage={this.handleChangePage}
                                        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                                        totalNumber={totalCountries}
                                    />
                                    :
                                    <GridContainer justify="center">
                                        <h4 className={classes.emptyListMsg}>No Country Found</h4>
                                    </GridContainer>
                            }
                        </CardBody>
                    </Card>
                    {addCountryForm &&
                        <AddCountryModal 
                            open={addCountryForm}
                            onClose={this.hideAddCountryForm}
                            submitForm={this.submitAddCountry}
                            type="Add"
                            values={{name: "", code2c: "", code3c: ""}}
                        />
                    }
                    {editCountryForm &&
                        <AddCountryModal 
                            open={editCountryForm}
                            onClose={this.hideEditCountryForm}
                            submitForm={this.submitUpdateCountry}
                            type="Update"
                            values={this.state.selectedCountry}
                        />
                    }
                </GridItem>
            </GridContainer>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Country));

class AddCountryModal extends React.PureComponent{
    render(){
        const { name, code2c, code3c } = this.props.values;
        return(
            <Dialog open={this.props.open} onClose={this.props.onClose}>
                <DialogTitle>{`${this.props.type} Country`}</DialogTitle>
                <Grid container>
                    <Formik
                        initialValues={{ name, code2c, code3c }}
                        validate={values => {
                            let errors = {};
                            if (!values.name) errors.name = 'Required';
                            if (!values.code2c) errors.code2c = 'Required';
                            if (!values.code3c) errors.code3c = 'Required';
                            return errors;
                        }}
                        onSubmit={this.props.submitForm}
                        onReset={this.props.onClose}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            handleReset
                        }) => (
                            <Form>
                                <MUICard>
                                    <CardContent>
                                        <TextField
                                            label="Name"
                                            placeholder="Enter Country Name"
                                            margin="dense"
                                            fullWidth
                                            name="name"
                                            value={values.name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={errors.name && touched.name && errors.name}
                                            error={errors.name && touched.name}
                                        />
                                        <TextField
                                            label="Code2c"
                                            placeholder="Enter Code2c"
                                            margin="dense"
                                            fullWidth
                                            name="code2c"
                                            value={values.code2c}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={errors.code2c && touched.code2c && errors.code2c}
                                            error={errors.code2c && touched.code2c}
                                        />
                                        <TextField
                                            label="Code3c"
                                            placeholder="Enter Code3c"
                                            margin="dense"
                                            fullWidth
                                            name="code3c"
                                            value={values.code3c}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={errors.code3c && touched.code3c && errors.code3c}
                                            error={errors.code3c && touched.code3c}
                                        />
                                    </CardContent>
                                    <CardActions>
                                        <Grid direction="row" justify="center" container={true} style={{ width: "100%" }}>
                                            <Button type="submit" color="primary" size="sm" onClick={handleSubmit} disabled={isSubmitting}>{this.props.type}</Button>
                                            <Button color="danger" size="sm" onClick={handleReset} disabled={isSubmitting}>Cancel</Button>
                                        </Grid>
                                    </CardActions>
                                </MUICard>
                            </Form>
                            )}
                    </Formik>
                </Grid>
            </Dialog>
        )
    }
}

const tableHead = [
    { id: "id", label: "Sr#", sortable: false },
    { id: "name", label: "Country Name", sortable: true },
    { id: "edit/delete", label: "Action", sortable: false },
];