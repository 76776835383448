import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { 
    Card as MUICard, CardContent, CardActions, TextField, Grid, InputLabel, MenuItem, FormControl, Select,
    Dialog, DialogTitle
} from "@material-ui/core";
import { Formik, Form } from "formik";
import {connect} from "react-redux"
import _ from "lodash";
import Spinner from "react-spinner-material";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "components/Table/Table.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "./../../components/CustomButtons/Button";
import {mapStateToProps, mapDispatchToProps} from "./functions";
import {getFormData} from "./../../helpers/helpers";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "400",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    emptyListMsg: {
        paddingTop: 20,
        paddingBottom: 20,
        margin: 0,
        color: "#0093cb"
    }
};

class Videos extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            addVideoForm: false,
            editVideoForm: false,
            selectedVideo: undefined,
            page: 0,
            loading: true
        }
    }
    componentDidMount(){
        this.props.getVideos(this.props.rowsPerPage, this.state.page * this.props.rowsPerPage, (res) => {
            this.setState({ loading: false })
        });
    }
    componentDidUpdate(prevProps, prevState){
        if (prevState.page !== this.state.page || prevProps.rowsPerPage !== this.props.rowsPerPage) {
            this.setState({loading: true});
            this.props.getVideos(this.props.rowsPerPage, this.state.page * this.props.rowsPerPage, (res) => {
                this.setState({ loading: false })
            });
        }
    }
    handleChangePage = (event, page) => {
        this.setState({ page });
    }
    handleChangeRowsPerPage = event => {
        this.setState({ page: 0 });
        this.props.setRowsPerPage(event.target.value);
    }
    showAddVideoForm = () => {
        this.setState({addVideoForm: true});
    }
    hideAddVideoForm = () => {
        this.setState({addVideoForm: false});
    }
    hideEditVideoForm = () => {
        this.setState({editVideoForm: false, selectedVideo: undefined});
    }
    submitAddVideo = (values, {setSubmitting}) => {
        let data = getFormData(values);
        this.props.addVideo(data, (status) => {setSubmitting(false); status && this.hideAddVideoForm()});
    }
    submitUpdateVideo = (values, {setSubmitting}) => {
        let data = getFormData(values);
        this.props.updateVideo(data, this.state.selectedVideo.id, (status) => {setSubmitting(false); status && this.hideEditVideoForm()});
    }
    editVideo = (id) => {
        let video = _.find(this.props.videos, video => video.id === id);
        if(!_.isUndefined(video)) this.setState({selectedVideo: video, editVideoForm: true});
    }
    removeVideo = (id) => {
        this.props.deleteVideo(id);
    }
    render(){
        const { classes, videos, rowsPerPage } = this.props;
        const { page, loading } = this.state;
        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className={classes.cardTitleWhite}>Videos</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={6} md={3} >
                                    <Button color="primary" fullWidth onClick={this.showAddVideoForm}>Add New Video</Button>
                                </GridItem>
                            </GridContainer>
                            {loading
                                ?
                                <GridContainer justify="center">
                                    <Spinner size={50} spinnerColor="#0093cb" spinnerWidth={5} visible={true} />
                                </GridContainer>
                                :
                                !_.isEmpty(videos)
                                    ?
                                    <Table
                                        tableHeaderColor="primary"
                                        tableHead={tableHead}
                                        tableData={videos}
                                        remove={this.removeVideo}
                                        editRow={this.editVideo}
                                        type="video"
                                        page={page}
                                        rowsPerPage={rowsPerPage}
                                        handleChangePage={this.handleChangePage}
                                        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    />
                                    :
                                    <GridContainer justify="center">
                                        <h4 className={classes.emptyListMsg}>No Video Found</h4>
                                    </GridContainer>
                            }
                        </CardBody>
                    </Card>
                    {this.state.addVideoForm &&
                        <VideoFormModal 
                            open={this.state.addVideoForm}
                            onClose={this.hideAddVideoForm}
                            submitForm={this.submitAddVideo}
                            type="Add"
                            values={{ title: "", url: "", sourceId: "", source: "" }}
                        />
                    }
                    {this.state.editVideoForm &&
                        <VideoFormModal
                            open={this.state.editVideoForm}
                            onClose={this.hideEditVideoForm}
                            submitForm={this.submitUpdateVideo}
                            type="Update"
                            values={this.state.selectedVideo}
                        />
                    }
                </GridItem>
            </GridContainer>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Videos));

class VideoFormModal extends React.PureComponent{
    render(){
        const { title, url, sourceId, source } = this.props.values;
        return(
            <Dialog open={this.props.open} onClose={this.props.onClose}>
                <DialogTitle>{`${this.props.type} Video`}</DialogTitle>
                <Grid container>
                    <Formik
                        initialValues={{ title, url, sourceId, source }}
                        validate={values => {
                            let errors = {};
                            if (!values.title) errors.title = 'Required';
                            if (!values.url) errors.url = 'Required';
                            if (!values.sourceId) errors.sourceId = 'Required';
                            if (!values.source) errors.source = 'Required';
                            return errors;
                        }}
                        onSubmit={this.props.submitForm}
                        onReset={this.props.onClose}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            handleReset
                        }) => (
                            <Form>
                                <MUICard>
                                    <CardContent>
                                        <TextField
                                            label="Title"
                                            placeholder="Enter Material Title"
                                            margin="dense"
                                            fullWidth
                                            name="title"
                                            value={values.title}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={errors.title && touched.title && errors.title}
                                            error={errors.title && touched.title}
                                        />
                                        <TextField
                                            label="Video URL"
                                            placeholder="Enter Video URL"
                                            margin="dense"
                                            fullWidth
                                            name="url"
                                            value={values.url}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={errors.url && touched.url && errors.url}
                                            error={errors.url && touched.url}
                                        />
                                        <TextField
                                            label="Video ID"
                                            placeholder="Enter Video ID"
                                            margin="dense"
                                            fullWidth
                                            name="sourceId"
                                            value={values.sourceId}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={errors.sourceId && touched.sourceId && errors.sourceId}
                                            error={errors.sourceId && touched.sourceId}
                                        />
                                        <FormControl fullWidth margin="dense">
                                            <InputLabel htmlFor="outlined-source-simple">Source</InputLabel>
                                            <Select
                                                name="source"
                                                value={values.source}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.source && touched.source}
                                                inputProps={{
                                                    name: "source",
                                                    id: "outlined-source-simple"
                                                }}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                <MenuItem value="Youtube">Youtube</MenuItem>
                                                <MenuItem value="Vimeo">Vimeo</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </CardContent>
                                    <CardActions>
                                        <Grid direction="row" justify="center" container={true} style={{ width: "100%" }}>
                                            <Button type="submit" color="primary" size="sm" onClick={handleSubmit} disabled={isSubmitting}>{this.props.type}</Button>
                                            <Button color="danger" size="sm" onClick={handleReset} disabled={isSubmitting}>Cancel</Button>
                                        </Grid>
                                    </CardActions>
                                </MUICard>
                            </Form>
                            )}
                    </Formik>
                </Grid>
            </Dialog>
        )
    }
}

const tableHead = [
    { id: "id", label: "Sr#", sortable: false },
    { id: "title", label: "Title", sortable: false },
    { id: "url", label: "Url", sortable: false },
    { id: "source", label: "Source", sortable: false },
    { id: "edit/delete", label: "Action", sortable: false },
];